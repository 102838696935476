import React, { Component } from 'react';
import "./ReferralManagemet.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import moment from "moment";
import clsx from 'clsx';
import { makeStyles } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseRounded from '@mui/icons-material/CloseRounded';
import DataTable from 'react-data-table-component';
import { Paper, TextField } from '@mui/material';
import md5 from "md5";
import ExportRefererUser from './ExportRefererUser';
import { Check } from '@mui/icons-material';

class ReferralManagement extends Component {
    state = { 
        columns : [
            {
                name: "Nama",
                selector: row => row.nama,
                sortable: true
            },
            {
                name: "No KTP",
                selector: row => row.no_ktp,
                sortable: true
            },
            {
                name: "Tempat Lahir",
                selector: row => row.tempat_lahir,
                sortable: true
            },
            {
                name: "tanggal Lahir",
                selector: row => row.tanggal_lahir,
                sortable: true
            },
            {
                name: "Kode Referral",
                selector: row => row.referal_code,
                sortable: true
            },
            {
                name: "Downline",
                selector: row => row.downline,
                sortable: true
            },
            {
                name: "Basic 1 Bulan",
                selector: row => row.basic_1_bulan,
                sortable: true
            },
            {
                name: "Basic 6 Bulan",
                selector: row => row.basic_6_bulan,
                sortable: true
            },
            {
                name: "Basic 12 Bulan",
                selector: row => row.basic_12_bulan,
                sortable: true
            },
            {
                name: "Pro 1 Bulan",
                selector: row => row.pro_1_bulan,
                sortable: true
            },
            {
                name: "Pro 6 Bulan",
                selector: row => row.pro_6_bulan,
                sortable: true
            },
            {
                name: "Pro 12 Bulan",
                selector: row => row.pro_12_bulan,
                sortable: true
            },
            {
                name: "Join Date",
                selector: row => row.created_at,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.created_at).toDate();
                    const b = moment(rowB.created_at).toDate();

                    return a > b ? 1 : -1

                }
            },
            {
                name: "Action",
                selector: row => row.referral !== undefined && row.referral !== null && row.referral.length > 0 ? <></> : row.action
            }
        ],
        data : [], 
        top: false,
        left: false,
        bottom: false,
        right: false,
        selectedUser : {
            nama: "",
            no_ktp: "",
            tanggal_lahir: "",
            tempat_lahir: "",
            referal_code: "",
            downline: 0,
            downline_users: []
        },
        //all, owner store, cashier
        role: "all",
        isLoading: false
    }

    defaultSelectedUser = () => {
        return {
            nama: "",
            no_ktp: "",
            tanggal_lahir: "",
            tempat_lahir: "",
            referal_code: "",
            downline: 0,
            benefit: 0,
            downline_users: []
        };
    }

    options = {
        filterType: 'checkbox',
    };

    loadUserMarketing = (role) => {

        const params = {
            match : {},
            sort  : {_id:-1},
            limit : 100
        };
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.adminListMarketing(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("USERSRESPONSE", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            responseData.data.forEach(element => {
                const userData = {
                    nama: element.nama, 
                    no_ktp: element.no_ktp, 
                    tempat_lahir: element.tempat_lahir,
                    tanggal_lahir: element.tanggal_lahir,
                    referal_code: element.referal_code,
                    downline: element.downline,
                    created_at: moment(element.created_at).format("YYYY-MM-DD"), 
                    benefit: element.benefit,
                    basic_1_bulan: element.basic_1_bulan,
                    basic_6_bulan: element.basic_6_bulan,
                    basic_12_bulan: element.basic_12_bulan,
                    pro_1_bulan: element.pro_1_bulan,
                    pro_6_bulan: element.pro_6_bulan,
                    pro_12_bulan: element.pro_12_bulan,
                    downline_users: [],
                    // action: this.action(element)
                };

                userData.action = this.action(userData);
                userDatas.push(userData);
            });

            this.setState({data: userDatas, isLoading: false});
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      };

    getUserInfo = (role) => {

        const params = {

        };
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
        this.setState({isLoading: false}, () => {
            axios
            .get(
                Api.findUser(this.state.selectedUser.no_ktp),
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("USERSRESPONSE", response.data);
                
                let selectedUser = this.state.selectedUser;
                let points = response.data.data.point;

                console.log("SELECTED_USER", selectedUser);
                // return;

                if(points.basic_1){
                    points.basic_1.forEach(item => item.package = "Basic 1 Bulan");
                    selectedUser.downline_users.push(...points.basic_1);
                }
                if(points.basic_6){
                    points.basic_6.forEach(item => item.package = "Basic 6 Bulan");
                    selectedUser.downline_users.push(...points.basic_6);
                }
                if(points.basic_12){
                    points.basic_12.forEach(item => item.package = "Basic 12 Bulan");
                    selectedUser.downline_users.push(...points.basic_12);
                }

                if(points.pro_1){
                    points.pro_1.forEach(item => item.package = "Pro 1 Bulan");
                    selectedUser.downline_users.push(...points.pro_1);
                }
                if(points.pro_6){
                    points.pro_6.forEach(item => item.package = "Pro 6 Bulan");
                    selectedUser.downline_users.push(...points.pro_6);
                }
                if(points.pro_12){
                    points.pro_12.forEach(item => item.package = "Pro 12 Bulan");
                    selectedUser.downline_users.push(...points.pro_12);
                }

                this.setState({selectedUser: selectedUser, isLoading: false}, () => {console.log(selectedUser);});
                
            })
            .catch((error) => {
                console.error("There was an error!", error);
                this.setState({isLoading: false});
            });
        });
        
      };

    addAdminMarketing = (role) => {

        const params = {
            nama: this.state.selectedUser.nama,
            no_ktp: this.state.selectedUser.no_ktp,
            tempat_lahir: this.state.selectedUser.tempat_lahir,
            tanggal_lahir: this.state.selectedUser.tanggal_lahir,
            referal_code: this.state.selectedUser.referal_code,
            benefit: parseInt(this.state.selectedUser.benefit)
        };
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.adminMarketing(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("INSERTMARKETING", response.data);
            
            this.setState({isLoading: false, right: false, selectedUser: this.defaultSelectedUser}, () => {
                this.loadUserMarketing()
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      };

        updateAdminMarketing = (role) => {

            const params = {
                nama: this.state.selectedUser.nama,
                no_ktp: this.state.selectedUser.no_ktp,
                tempat_lahir: this.state.selectedUser.tempat_lahir,
                tanggal_lahir: this.state.selectedUser.tanggal_lahir,
                referal_code: this.state.selectedUser.referal_code,
                benefit: parseInt(this.state.selectedUser.benefit),
                role: "marketing"
            };
        
            console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
        
            this.setState({isLoading: true}, () => {
                axios
            .put(
                Api.adminMarketing(),
                params,
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("INSERTMARKETING", response.data);
                
                this.setState({isLoading: false, right: false, selectedUser: this.defaultSelectedUser}, () => {
                    this.loadUserMarketing();
                });
                
            })
            .catch((error) => {
                console.error("There was an error!", error);
                this.setState({isLoading: false});
            });
            });
        
        };

      componentDidMount() {
          this.loadUserMarketing();
      }

      toggleDrawer = (open, userInfo) => (event) => {
          console.log(userInfo);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        
        let defaultInfo = this.defaultSelectedUser();

        this.setState({right: open, selectedUser: !open ? defaultInfo : userInfo }, () => {
            if(open){
                this.getUserInfo("");
            }
        });
      };

    action = (userInfo) => {      
        return (
            <div>
                <span className="btn btn-primary" onClick={this.toggleDrawer(true, userInfo)}>Edit</span>&nbsp;
                {/* <span className="btn btn-primary" onClick={this.toggleDrawer(true, userInfo)}>Gen Code</span> */}
            </div>
        );
    }

    handleNamaChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.nama = event.target.value;

        this.setState({selectedUser : selectedUserData}, () => {
            console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    handleNoIdChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.no_ktp = event.target.value;

        this.setState({selectedUser : selectedUserData}, () => {
            console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    handleBenefitChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.benefit = event.target.value;

        this.setState({selectedUser : selectedUserData}, () => {
            console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    handlePlaceOfBirthChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.tempat_lahir = event.target.value;

        this.setState({selectedUser : selectedUserData}, () => {
            console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    handleDateOfBirthChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.tanggal_lahir = event.target.value;
        console.log("TTL : ", selectedUserData.date_of_birth);
        this.setState({selectedUser : selectedUserData}, () => {
            console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }

    handleReferralCodeChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.referal_code = event.target.value;

        this.setState({selectedUser : selectedUserData}, () => {
            console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }

    handleGenerateReferalCode = () => {
        let datettime = moment().format("YYYY-MM-DD h:mm:ss a");
        console.log("GENERATED : ", md5(datettime));
        let selectedUserData = this.state.selectedUser;
        let firtsThreeChar = selectedUserData.nama.replace(" ", "").substring(0, 3);
        selectedUserData.referal_code = firtsThreeChar.toLowerCase() + md5(datettime).substring(29, 32);

        this.setState({selectedUser : selectedUserData}, () => {
            console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }

    list(anchor,){
        return (
        <div className="info" role="presentation"
        //   onClick={this.toggleDrawer(anchor, false)}
        //   onKeyDown={this.toggleDrawer(anchor, false)}
        >
          <div className="container">
              <div className="row">
                    <div className="col-11">
                        <h1>Infor User Marketing</h1>
                    </div>
                   
                  <div className="col-1">
                      <CloseRounded onClick={() => {this.toggleDrawer(false, {})}} />
                  </div>
              </div>
              {/* <br /><br />
                <div className="text-center">
                    <img src="https://picsum.photos/id/1/200/300" className="avatar" alt=""/>
                </div> */}
              <br />
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleNamaChange} 
                            id="txt_name" 
                            label="Nama" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedUser.nama} 
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleNoIdChange}
                            id="txt_no_id" 
                            label="No KTP" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedUser.no_ktp} 
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField
                            onChange={this.handlePlaceOfBirthChange}
                            id="txt_place_of_birth" 
                            label="Tempat Lahir" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedUser.tempat_lahir} 
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleDateOfBirthChange}
                            id="txt_date_of_birth" 
                            label="Tanggal Lahir" 
                            fullWidth 
                            variant="outlined"
                            type="date" 
                            value={this.state.selectedUser.tanggal_lahir} 
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-9">
                        Kode Referral
                        <br />
                        <div className="text-danger">
                            <strong>
                            {this.state.selectedUser.referal_code === undefined | this.state.selectedUser.referal_code === null | this.state.selectedUser.referal_code === "" ? "XXXXXX (Klik generate)" : this.state.selectedUser.referal_code}
                            </strong>
                        </div>
                        <hr />
                        {
                            this.state.selectedUser.referal_code === undefined | this.state.selectedUser.referal_code === null | this.state.selectedUser.referal_code === "" ? (<></>) : 
                            (
                                <div className="row row-padding">
                                    <div className="col-12">
                                        <TextField 
                                            onChange={this.handleBenefitChange}
                                            id="txt_benefit" 
                                            label="Benefit Referral (Hari)" 
                                            fullWidth 
                                            variant="outlined" 
                                            value={this.state.selectedUser.benefit} 
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {/* <TextField 
                            onChange={this.handleReferralCodeChange}
                            id="txt_referal_code" 
                            disabled 
                            // defaultValue={this.state.selectedUser.referal_code}
                            // label="Kode Referral" 
                            fullWidth 
                            // variant="outlined" 
                            value={this.state.selectedUser.referal_code} 
                        /> */}
                    </div>
                    <div className="col-3">
                        {
                            this.state.selectedUser.referal_code === undefined | 
                            this.state.selectedUser.referal_code === null | 
                            this.state.selectedUser.referal_code === "" ? 
                            (
                                <span className="btn btn-warning" style={{marginTop: "14px"}} onClick={this.handleGenerateReferalCode}>
                                    Generate
                                </span>
                            ) : 
                            (<></>)
                        }
                    </div>
                </div>
                <hr />
                <div className="text-center">
                    <span className="btn btn-primary" onClick={() => this.state.selectedUser._id !== undefined && this.state.selectedUser._id != null ? this.updateAdminMarketing() : this.addAdminMarketing()}>
                        Simpan
                    </span>
                </div>
          </div>
          <br />
          <div className='text-center'>
            <h4>Referral Users</h4>
          </div>
          <div className="container">
            <Paper style={{padding: "5px"}}>
                <div className="row">
                    <div className="col-4 text-center">
                        <strong>Nama</strong>
                    </div>
                    <div className="col-4 text-center">
                        <strong>USSD</strong>
                    </div>
                    <div className="col-4 text-center">
                        <strong>Paket</strong>
                    </div>
                </div>
                
                <div className="container">
                {
                    this.state.selectedUser.downline_users !== undefined && this.state.selectedUser.downline_users.length > 0 ? this.state.selectedUser.downline_users.map((element) => (
                        <div className="row">
                            <div className="col-4 border rounded" style={{padding: "5px"}}>
                                {element.fullname}
                            </div>
                            <div className="col-4 border rounded" style={{padding: "5px"}}>
                                {element.ussd}
                            </div>
                            <div className="col-4 border rounded" style={{padding: "5px"}}>
                                {element.package}
                            </div>
                        </div>
                    )) : (
                        <div className="row">
                            <div className="col-12 text-center" style={{padding: "5px"}}>
                                Belum ada downline
                            </div>
                        </div>
                    )
                }
                </div>
                
            </Paper>
          </div>
        </div>
      );
    }

    getFilterClasses = (role) => {
        let classes = "col-4";
        if(role === this.state.role){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    handleChangeFilter = (role) => {
        console.log("ROLE : ", role);
        this.setState({role: role});
        this.loadUserMarketing(role);
    }

    render() { 
        return ( 
            <div>
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{})}
                    >
                    {this.list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}

                <div className="container-fluid no-padding">
                    <div className="container-fluid header-page">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <h2 className="mt-0">Referral User Management</h2>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-right">
                                {/* <span className="btn btn-primary btn-sm" onClick={this.toggleDrawer(true, {})}>Bulk Import</span>&nbsp; */}
                                <ExportRefererUser data={this.state.data} />&nbsp;
                                <span className="btn btn-primary btn-sm" onClick={this.toggleDrawer(true, {})}>Tambah</span>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid dashboard-content">
                        {/* <div className="row">
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                <small>Tampilkan hasil untuk :</small> 
                                <br />
                                {this.state.role === "all" ? "Semua" : this.state.role === "owner store" ? "Pemilik" : "Kasir"}
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-8 col-xs-12">
                                <div className="row filter-container">
                                    <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                        Semua
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("owner store")} className={this.getFilterClasses("owner store")}>
                                        Pemilik
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("cashier")} className={this.getFilterClasses("cashier")}>
                                        Kasir
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <br /> */}
                        <DataTable 
                            title="" 
                            data={this.state.data} 
                            columns={this.state.columns} 
                            options={this.options} 
                            // selectableRows
                            pagination
                            progressPending={this.state.isLoading}
                            // progressComponent={<div>Loading</div>}
                        />

                    </div>
                </div>
            </div>
        );
    }
}
 
export default ReferralManagement;