import { TextField,FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import React, { Component } from 'react';
import axios from "axios";
import Api from "../../lib/Api";
import {EditorState} from 'draft-js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class TabContentInputArtikelTutorial extends Component {
    state = { 
        id: null,
        title: "",
        description: "",
        img_url: "",
        video_url: "",
        status: "active",
        category: "article",
        files: [],
        categories:[],
        selectedCategory : "article",
        editorState: EditorState.createEmpty()
     }

     editor;

     handleSaveFile = (files) => {
        console.log("FILES : ", files);
        this.setState({
            files: files,
        });
    }

    handleSaveTitle = (event) =>{
        this.setState({title: event.target.value});
    }

    handleSaveDescription = (event) =>{
        console.log(event);
        this.setState({description: event});
        // this.setState({description: event.target.value});
    }
    
    handleSaveImageUrl = (event) =>{
        this.setState({img_url: event.target.value});
    }

    handleSaveVideoUrl = (event) =>{
        this.setState({video_url: event.target.value});
    }

    handleSaveStatus = (event) =>{
        this.setState({status: event.target.value});
    }

    handleSaveCategory = (event) =>{
        console.log("KATEGORI", event.target.value);
        this.setState({category: event.target.value});
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState: editorState,
        });
      };

    handleCheckBoxChange = (event) => {

        // if(event.target.checked){
        //     this.props.onChecked(this.state.status);
        // }else{
        //     this.props.onUnchecked(this.state.selectedSales);
        // }

        this.setState({status: event.target.checked ? "active" : "inactive"});
      }

    initData = (data) => {
        console.log("INITPRODUCT", data);

        if(data._id !== undefined && data._id !== null){
            this.setState(
                {
                    id: data._id,
                    title: data.title,
                    description: data.description,
                    img_url: data.img_url,
                    video_url: data.video_url,
                    status: data.status,
                    category: data.category,
                    files: [],
                    selectedCategory : data.category,
                }, () => {
                    console.log("INITSTATE", this.state);
                }
            );
        }else{
            this.setState(
                {
                    id: null,
                    title: "",
                    description: "",
                    img_url: "",
                    video_url: "",
                    status: "active",
                    category: "article",
                    files: [],
                    selectedCategory : "article"
                }, () => {
                    console.log("INITSTATE", this.state);
                }
            );
        }

        
    }

      componentDidMount() {
          if(this.props.selectedProduct !== null){
            this.initData(this.props.selectedProduct);
            console.log("initproduct", this.props.selectedProduct);
          }
          this.focusEditor();
      }

      handleSelectCategory = (value) => {
          console.log("SELECTED CATEGORY : ",value);
          this.setState({selectedCategory: value, category: value});
      }
      
      uploadFoto = () => {
        const data = new FormData() 
        data.append('photo', this.state.files[0]);
        axios
        .post(Api.uploadImage(), data, Api.getRequestHeader())
        .then((response) => {
            console.log("RESPONSEUPLOAD : ", response.data);
        //   this.setState({files: []});
            const imagePath = response.data.path;
            if(this.state.id === null){
                this.addProduct(imagePath);
            }else{
                this.updateProduct(imagePath);
            }
            
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message });
          console.error("There was an error!", error);
        });
      }

      addProduct = (photoUrl) => {
        var converted = this.state.description.replaceAll("\n", "<p></p>");
        const params = {
            title: this.state.title,
            // description: JSON.stringify(converted),
            description: converted,
            img_url: this.state.img_url,
            video_url: this.state.video_url,
            status: this.state.status,
            category: this.state.category,
        };

        console.log("PARMADD", params);

        axios
        .post(
          Api.saveArtikel(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("ADDPRODUCTREPONSE", response.data);
          this.setState(
              {
                id: null,
                title: "",
                description: "",
                img_url: "",
                video_url: "",
                status: "active",
                category: "",
                files: [],
                categories:[],
                selectedCategory : "",
              }
          );
          this.props.onDataChange();
          this.props.closeForm();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      }

      updateProduct = (photoUrl) => {
        // var converted = this.state.description.replaceAll("\n", "<p></p>");
        const params = {
            title: this.state.title,
            description: this.state.description,
            img_url: this.state.img_url,
            video_url: this.state.video_url,
            status: this.state.status,
            category: this.state.category,
        };

        console.log("PARMUPDATE", params);

      axios
      .put(
        Api.saveArtikel() + "/" + this.state.id,
        params,
        Api.getRequestHeader()
      )
      .then((response) => {
        console.log("UPDATEPRODUCTREPONSE", response.data);
        this.setState(
            {
                id: null,
                title: "",
                description: "",
                img_url: "",
                video_url: "",
                status: "active",
                category: "",
                files: [],
                categories:[],
                selectedCategory : "",
              }
        );
        this.props.onDataChange();
        this.props.closeForm();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    }

    saveProduct = () => {

        // var converted = this.state.description.replaceAll("\n", "<p></p>");
        // console.log(converted);

        // return;

        if(this.state.id === null){//assumed this is new product
            if(this.state.files.length > 0){
                this.uploadFoto();
            }else{
                this.addProduct(null);
            }
        }else{//assumed we will update the product info
            if(this.state.files.length > 0){
                this.uploadFoto();
            }else{
                this.updateProduct(this.state.foto);
            }
        }
    }

    onEditorChange = (editorState) => this.setState({editorState});
    
    setEditor = (editor) => {
      this.editor = editor;
    };

    focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };

    render() { 
        return ( 
            <>
            <hr />
            <TextField
                className="input-textfield"
                id="article-title"
                label="Judul"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveTitle}
                onKeyDown={this.onKeyDownHandler}
                value={this.state.title}
                // style={this.CssTextField}
            />

            <br /><br />

            {/* <Editor
                initialContentState={this.state.description}
                // editorState={this.state.editorState}
                // toolbarClassName="toolbarClassName"
                // wrapperClassName="wrapperClassName"
                // editorClassName="editorClassName"
                onEditorStateChange={this.onEditorStateChange}
            /> */}

            {/* <TextField
                className="input-textfield"
                id="article-description"
                label="Deskripsi"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveDescription}
                onKeyDown={this.onKeyDownHandler}
                value={this.state.description}
                // style={this.CssTextField}
            /> */}
            
            <div className="text-secondary">Deskripsi</div>
            <ReactQuill onChange={this.handleSaveDescription} value={this.state.description} theme="snow" />
            {/* <TextareaAutosize
                variant="outlined"
                maxRows={10}
                minRows={4}
                aria-label="maximum height"
                placeholder="Deskripsi artikel / tutorial"
                onChange={this.handleSaveDescription}
                onKeyDown={this.onKeyDownHandler}
                value={this.state.description.replaceAll("<p></p>", "\n")}
                defaultValue=""
                style={{ width: "100%" }}
            /> */}
            {/* <DraftJsEditor /> */}
            {/* <div style={styles.editor} onClick={this.focusEditor}>
                <Editor
                    ref={this.setEditor}
                    editorState={this.state.editorState}
                    onChange={this.onEditorChange}
                />
            </div> */}
            
            <br /><br />
            <input type="checkbox" checked={this.state.status === "active"} onChange={this.handleCheckBoxChange} /> Artikel Aktif
            
            <br /><br />
            <FormControl variant="outlined" style={{width: '100%'}}>
                <InputLabel id="demo-simple-select-outlined-label">Kategori</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.state.category}
                    onChange={this.handleSaveCategory}
                    label="Kategori"
                >
                    <MenuItem key="article" value="article">Artikel</MenuItem>
                    <MenuItem key="tutorial" value="tutorial">Tutorial</MenuItem>
                </Select>
            </FormControl>

            <br /><br />

            <TextField
                className="input-textfield"
                id="image-url"
                label="Image Url"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveImageUrl}
                onKeyDown={this.onKeyDownHandler}
                value={this.state.img_url}
                // style={this.CssTextField}
            />

            <br /><br />

            <TextField
                className="input-textfield"
                id="video-url"
                label="Video Url"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveVideoUrl}
                onKeyDown={this.onKeyDownHandler}
                value={this.state.video_url}
                // style={this.CssTextField}
            />
            
            {/* <br /><br />
            <div className="">
                <strong>Gambar</strong>
                <br/>
                <img style={{width: 150}} className="img-responsive" src={this.state.img_url != null && this.state.img_url.indexOf("http") !== -1 ? this.state.img_url : Api.getApiUrl() + this.state.img_url} alt="" />
                <DropzoneArea
                    onChange={this.handleSaveFile.bind(this)}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'image/svg']}
                    // showPreviews={true}
                    maxFileSize={5000000}
                    filesLimit={1}
                />
            </div>

            <br /><br />
            <div className="">
                <strong>Video</strong>
                <br/>
                <img style={{width: 150}} className="img-responsive" src={this.state.video_url != null && this.state.video_url.indexOf("http://") !== -1 ? this.state.foto : Api.getApiUrl() + this.state.video_url} alt="" />
                <DropzoneArea
                    onChange={this.handleSaveFile.bind(this)}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'image/svg']}
                    // showPreviews={true}
                    maxFileSize={5000000}
                    filesLimit={1}
                />
            </div> */}

            <br /><br />
            <div className="text-right">
                <div onClick={this.saveProduct} className="btn btn-primary">Simpan</div>
            </div>
            </>
         );
    }
}
 
export default TabContentInputArtikelTutorial;