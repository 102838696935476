import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportRefererUser extends Component {

    getDatas = () => {
        let leadsData = [];
        this.props.data.forEach(element => {
            leadsData.push(element);
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary btn-sm"><ImportExport />Export ke Excel</button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getDatas} name="Referer Data">
                    <ExcelColumn label="Nama" value="nama"/>
                    <ExcelColumn label="No KTP" value="no_ktp"/>
                    <ExcelColumn label="Tempat Lahir" value="tempat_lahir"/>
                    <ExcelColumn label="Tanggal Lahir" value="tanggal_lahir"/>
                    <ExcelColumn label="Kode Referal" value="referal_code"/>
                    <ExcelColumn label="Join Date" value="created_at" />
                                {/* //   value={(col) => moment(col.created_at).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/> */}
                    <ExcelColumn label="Downline" value={(col) => col.downline.length}/>
                    <ExcelColumn label="Basic 1 Bulan" value={(col) => col.basic_1_bulan}/>
                    <ExcelColumn label="Basic 6 Bulan" value={(col) => col.basic_6_bulan}/>
                    <ExcelColumn label="Basic 12 Bulan" value={(col) => col.basic_12_bulan}/>
                    <ExcelColumn label="Pro 1 Bulan" value={(col) => col.pro_1_bulan + ""}/>
                    <ExcelColumn label="Pro 6 Bulan" value={(col) => col.pro_6_bulan}/>
                    <ExcelColumn label="Pro 12 Bulan" value={(col) => col.pro_12_bulan}/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportRefererUser;