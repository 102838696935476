import React, { Component } from 'react';
import possaxImageLogo from "../../assets/image/possax-login-logo.png";
import "./Login.css";
import axios from "axios";
import Api from "../../lib/Api";
import Global from "../../lib/Global";
import querystring from "querystring";
import TextField from '@mui/material/TextField';
// import {
//     fade,
//     ThemeProvider,
//     // withStyles,
//     makeStyles,
//     createMuiTheme,
//   } from '@mui/material';
import {ArrowDownward} from "@mui/icons-material";

class Login extends Component {
    state = { 
        email: "",
        password: "",
        errorMessage: "",
        loginSuccess: true
     }

    //  CssTextField = withStyles({
    //     root: {
    //       '& label.Mui-focused': {
    //         color: 'green',
    //       },
    //       '& .MuiInput-underline:after': {
    //         borderBottomColor: 'green',
    //       },
    //       '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //           borderColor: 'red',
    //         },
    //         '&:hover fieldset': {
    //           borderColor: 'yellow',
    //         },
    //         '&.Mui-focused fieldset': {
    //           borderColor: 'green',
    //         },
    //       },
    //     },
    //   })(TextField);

    doLogin = () => {
        const params = {
          username: this.state.email,
          password: ""+this.state.password,
        };
    
        console.log("PARAMS LOGIN", params);
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .post(
            Api.login(),
            querystring.stringify(params),
            // params,
            Api.getRequestHeaderNoAuth()
          )
          .then((response) => {
            console.log("Login RESPONSE", response.data);
            let responseData = response.data;
            if (responseData.success) {
              localStorage.setItem("auth-possaku", JSON.stringify(responseData));
              Global.add(responseData);
              this.props.onLogin();
            } else {
              console.log("GAGALLOGIN", responseData.message, responseData.success);
              this.setState({
                errorMessage: responseData.message,
                loginSuccess: false,
              });
            }
          })
          .catch((error) => {
            this.setState({ errorMessage: error.message, loginSuccess: false });
            console.error("There was an error!", error);
            this.props.onLogin();
          });
      };

      handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
        // console.log(this.state.email);
      };
    
      handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
        // console.log(this.state.password);
      };
    
      onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          this.doLogin();
        }
      };

    render() { 
        return ( 
            <div className="container text-center login-container">
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <img src={possaxImageLogo} />
                        
                        <div>
                            <TextField
                                className="input-textfield"
                                // id="outlined-required"
                                label="Username"
                                defaultValue=""
                                variant="outlined"
                                onChange={this.handleEmailChange}
                                onKeyDown={this.onKeyDownHandler}
                                // style={this.CssTextField}
                            />
                        </div>
                        <br />
                        <div>
                            <TextField
                                className="input-textfield"
                                // id="outlined-required"
                                label="Password"
                                defaultValue=""
                                type="password"
                                variant="outlined"
                                onChange={this.handlePasswordChange}
                                onKeyDown={this.onKeyDownHandler}
                                // color="#53C4FF"
                            />
                        </div>
                        <br />
                        <div
                        className={
                            this.state.loginSuccess
                            ? "d-none"
                            : "alert alert-danger"
                        }
                        role="alert"
                        >
                        {this.state.errorMessage}
                        <br />
                        </div>
                        <div onClick={this.doLogin} className="btn btn-primary btn-login">Masuk</div>
                    </div>
                    <div className="col-4"></div>
                </div>
            </div>
         );
    }
}
 
export default Login;