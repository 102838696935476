import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import moment from "moment";
import { Button } from '@mui/material';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportStores extends Component {

    getDatas = () => {
        let leadsData = [];
        this.props.data.forEach(element => {
            leadsData.push(element);
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<Button variant='contained' size='small' color='primary'><ImportExport />Export ke Excel</Button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getDatas} name="Store Data">
                    <ExcelColumn label="Nama Toko" value="nama_toko"/>
                    <ExcelColumn label="Alamat" value="alamat"/>
                    <ExcelColumn label="Jenis Usaha" value="jenis_usaha"/>
                    <ExcelColumn label="Pemilik" value="owner_name"/>
                    <ExcelColumn label="No Pemilik" value="owner_ussd"/>
                    <ExcelColumn label="Saldo Transaksi" value="cashier"/>
                    <ExcelColumn label="Transaksi Terakhir" value="last_transaction" />
                                {/* //   value={(col) => moment(col.created_at).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/> */}
                    {/* <ExcelColumn label="Downline" value={(col) => col.downline.length}/> */}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportStores;