import React, { Component, useEffect } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DashboardGA from '../GoogleAnalytics';
import DashboardOverview from '../DashboardOverview';
import DashboardUserManagement from '../DashboardUserManagement';
import { Analytics, Summarize, ContactPage, Store, Inventory, Newspaper, Lock, NotificationAdd, Payment, Sell, TagTwoTone, Discount, HowToVote, DesignServices } from '@mui/icons-material';
import DashboardStoreManagement from '../DashboardStoreManagement';
import DashboardProductsManagement from '../DashboardProductsManagement';
import DashboardArtikelTutorial from '../DashboardArtikelTutorial';
import ReferralManagement from '../ReferralManagement';
import SubscriptionSetup from '../SubscriptionSetup';
import Subscription from '../Subscription';
import Vouchers from '../Vouchers';
import QrisProviders from '../QrisProviders';
import VoucherDiscount from '../VoucherDiscount';
import Survey from '../Survey';
import CustomerService from '../CustomerService';

const drawerWidth = 240;

function MainPage(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState({title: 'GA Dashboard', longTitle: "Google Analytics Dashboard", key: "1"})

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("auth-possaku");
    props.onAuthChange();
  };

  const getMenuIconClassname = (key) => {
    return key === selectedMenu.key ? "text-primary" : "";
  }

  const getMenuTextClassname = (key) => {
    return key === selectedMenu.key ? "text-primary" : "";
  }

  const menuOnClick = (menu) => {
    setSelectedMenu(menu);
    if(mobileOpen){
      handleDrawerToggle();
    }
  }

  const menuItems = [
    {title: 'GA Dashboard', longTitle: "Google Analytics Dashboard", key: "1", icon: (<Analytics className={getMenuIconClassname("1")} />)}, 
    {title: 'Overview', longTitle: "Overview", key: "2", icon: (<Summarize className={getMenuIconClassname("2")} />)}, 
    {title: 'Users', longTitle: "User Management", key: "3", icon: (<ContactPage className={getMenuIconClassname("3")} />)}, 
    {title: 'Referral', longTitle: "Referral Management", key: "4", icon: (<ContactPage className={getMenuIconClassname("4")} />)}, 
    {title: 'Stores', longTitle: "Store Management", key: "5", icon: (<Store className={getMenuIconClassname("5")} />)}, 
    {title: 'Products', longTitle: "Products Management", key: "6", icon: (<Inventory className={getMenuIconClassname("6")} />)}, 
    {title: 'Artikel & Tutorial', longTitle: "Artikel & Tutorial", key: "7", icon: (<Newspaper className={getMenuIconClassname("7")} />)},
    {title: 'Subscription', longTitle: "Subscription", key: "8", icon: (<Payment className={getMenuIconClassname("8")} />)},
    {title: 'Subscription Setup', longTitle: "Subscription Setup", key: "9", icon: (<Sell className={getMenuIconClassname("9")} />)},
    {title: 'Survey', longTitle: "Survey", key: "10", icon: (<HowToVote className={getMenuIconClassname("10")} />)},
    // {title: 'Vouchers', longTitle: "Vouchers", key: "10", icon: (<TagTwoTone className={getMenuIconClassname("10")} />)},
    {title: 'QRIS Vendor', longTitle: "QRIS Vendor", key: "11", icon: (<TagTwoTone className={getMenuIconClassname("11")} />)},
    {title: 'Gift Card', longTitle: "Gift Card", key: "12", icon: (<Discount className={getMenuIconClassname("12")} />)},
    {title: 'Customer Service', longTitle: "Customer Service", key: "13", icon: (<DesignServices className={getMenuIconClassname("13")} />)}
  ];

  const drawer = (
    <div>
      <Toolbar className='text-center'>
        <strong>PosSaku Dashboard</strong>
      </Toolbar>
      <Divider />
      <List>
        {menuItems.map((menu, index) => (
            
          <ListItem key={menu.key} disablePadding onClick={() => menuOnClick(menu)}>
            <ListItemButton>
              <ListItemIcon>
                {menu.icon}
              </ListItemIcon>
              <ListItemText primary={<small>{menu.title}</small>} className={getMenuTextClassname(menu.key)} />
            </ListItemButton>
          </ListItem>
          
        ))}
      </List>
      <Divider />
      <List>
          <ListItem key="Logout" disablePadding onClick={handleLogout}>
            <ListItemButton>
              <ListItemIcon>
                <Lock />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
      </List>

    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {selectedMenu.longTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        
      >
        <Toolbar />
         {
            selectedMenu.key === "1" ? (<DashboardGA />) : (<></>)
         }
         {
            selectedMenu.key === "2" ? (<DashboardOverview />) : (<></>)
         }
         {
            selectedMenu.key === "3" ? (<DashboardUserManagement />) : (<></>)
         }
         {
            selectedMenu.key === "4" ? (<ReferralManagement />) : (<></>)
         }
         {
            selectedMenu.key === "5" ? (<DashboardStoreManagement />) : (<></>)
         }
         {
            selectedMenu.key === "6" ? (<DashboardProductsManagement />) : (<></>)
         }
         {
            selectedMenu.key === "7" ? (<DashboardArtikelTutorial />) : (<></>)
         }
         {
            selectedMenu.key === "8" ? (<Subscription />) : (<></>)
         }
         
         {
            selectedMenu.key === "9" ? (<SubscriptionSetup />) : (<></>)
         }

         {
            selectedMenu.key === "10" ? (<Survey />) : (<></>)
         }

         {/* {
            selectedMenu.key === "10" ? (<Vouchers />) : (<></>)
         } */}

         {
            selectedMenu.key === "11" ? (<QrisProviders />) : (<></>)
         }

         {
            selectedMenu.key === "12" ? (<VoucherDiscount />) : (<></>)
         }

         {
            selectedMenu.key === "13" ? (<CustomerService />) : (<></>)
         }
      </Box>
    </Box>
  );
}

export default MainPage;

// class MainPage extends Component {
//     state = { 
//         drawerWidth : 240,
//         mobileOpen : false
//     } 

//     handleDrawerToggle = (mobileOpen) => {
//         this.setState({mobileOpen: !mobileOpen});
//     }
    
//     container = () => { return this.props.window !== undefined ? this.props.window().document.body : undefined; };

//     render() { 
//         return (
//             <Box sx={{ display: 'flex' }}>
//               <CssBaseline />
//               <AppBar
//                 position="fixed"
//                 sx={{
//                   width: { sm: `calc(100% - ${this.state.drawerWidth}px)` },
//                   ml: { sm: `${this.state.drawerWidth}px` },
//                 }}
//               >
//                 <Toolbar>
//                   <IconButton
//                     color="inherit"
//                     aria-label="open drawer"
//                     edge="start"
//                     onClick={this.handleDrawerToggle}
//                     sx={{ mr: 2, display: { sm: 'none' } }}
//                   >
//                     <MenuIcon />
//                   </IconButton>
//                   <Typography variant="h6" noWrap component="div">
//                     {this.props.title}
//                   </Typography>
//                 </Toolbar>
//               </AppBar>
//               <Box
//                 component="nav"
//                 sx={{ width: { sm: this.state.drawerWidth }, flexShrink: { sm: 0 } }}
//                 aria-label="mailbox folders"
//               >
//                 <MyDrawer drawerWidth={this.state.drawerWidth} mobileOpen={this.state.mobileOpen} handleDrawerToggle={this.handleDrawerToggle}/>
//                 {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
//                 {/* <Drawer
//                   container={this.container()}
//                   variant="temporary"
//                   open={this.state.mobileOpen}
//                   onClose={this.handleDrawerToggle}
//                   ModalProps={{
//                     keepMounted: true, // Better open performance on mobile.
//                   }}
//                   sx={{
//                     display: { xs: 'block', sm: 'none' },
//                     '& .MuiDrawer-paper': { boxSizing: 'border-box', width: this.state.drawerWidth },
//                   }}
//                 >
//                   {drawerMenuList}
//                 </Drawer>
//                 <Drawer
//                   variant="permanent"
//                   sx={{
//                     display: { xs: 'none', sm: 'block' },
//                     '& .MuiDrawer-paper': { boxSizing: 'border-box', width: this.state.drawerWidth },
//                   }}
//                   open
//                 >
//                   {drawerMenuList}
//                 </Drawer> */}
//               </Box>
//               <Box
//                 component="main"
//                 sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${this.state.drawerWidth}px)` } }}
//               >
//                 <Toolbar />
//                 <div>
//                 {this.props.children}
//                 </div>
//               </Box>
//             </Box>
//           );
//     }
// }
 
// export default MainPage;

// function MyDrawer(props){
//     const { window } = props;

//     const container = window !== undefined ? () => window().document.body : undefined;

//     // useEffect(() => {
//     //     container = window;
//     // });

//     return(
//         <Box
//         component="nav"
//         sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }}
//         aria-label="mailbox folders"
//         >
//         {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
//         <Drawer
//             container={container}
//             variant="temporary"
//             open={props.mobileOpen}
//             onClose={props.handleDrawerToggle}
//             ModalProps={{
//             keepMounted: true, // Better open performance on mobile.
//             }}
//             sx={{
//             display: { xs: 'block', sm: 'none' },
//             '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
//             }}
//         >
//             {drawerMenuList}
//         </Drawer>
//         <Drawer
//             variant="permanent"
//             sx={{
//             display: { xs: 'none', sm: 'block' },
//             '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
//             }}
//             open
//         >
//             {drawerMenuList}
//         </Drawer>
//         </Box>
//     );
// }

// function drawerMenuList(props) {

//     return (
//         <div>
//           <Toolbar />
//           <Divider />
//           <List>
//             {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//               <ListItem key={text} disablePadding>
//                 <ListItemButton>
//                   <ListItemIcon>
//                     {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                   </ListItemIcon>
//                   <ListItemText primary={text} />
//                 </ListItemButton>
//               </ListItem>
//             ))}
//           </List>
//           <Divider />
//           <List>
//             {['All mail', 'Trash', 'Spam'].map((text, index) => (
//               <ListItem key={text} disablePadding>
//                 <ListItemButton>
//                   <ListItemIcon>
//                     {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                   </ListItemIcon>
//                   <ListItemText primary={text} />
//                 </ListItemButton>
//               </ListItem>
//             ))}
//           </List>
//         </div>
//       );
// } 


