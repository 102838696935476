import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { AssignmentReturned, ImportExport, Widgets } from "@mui/icons-material";
import moment from "moment";
import { Button } from '@mui/material';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadTemplateProductBulk extends Component {

    getDatas = () => {
        let leadsData = [{
            nama_toko: "dummy",
            foto: "dummy",
            quantity: 0,
            harga_jual: 0,
            harga_modal: 0,
            sku: "dummy",
            kategori: "dummy",
            status: "active",
            kode_barcode: "dummy",
            store_id: this.props.storeId
        }];
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<Button variant='contained' color='primary'><Widgets />&nbsp; Download Template</Button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getDatas} name="Sheet 1">
                    <ExcelColumn label="nama" value="nama_toko"/>
                    <ExcelColumn label="foto" value="foto"/>
                    <ExcelColumn label="quantity" value="quantity"/>
                    <ExcelColumn label="harga_jual" value="harga_jual"/>
                    <ExcelColumn label="harga_modal" value="harga_modal"/>
                    <ExcelColumn label="sku" value="sku"/>
                    <ExcelColumn label="kategori" value="kategori" />
                    <ExcelColumn label="status" value="status" />
                    <ExcelColumn label="kode_barcode" value="kode_barcode" />
                    <ExcelColumn label="store_id" value="store_id" />
                                {/* //   value={(col) => moment(col.created_at).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/> */}
                    {/* <ExcelColumn label="Downline" value={(col) => col.downline.length}/> */}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default DownloadTemplateProductBulk;