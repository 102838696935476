import { Box, LinearProgress, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Api from "../../lib/Api";
import DataTable from 'react-data-table-component';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import "./GoogleAnalytics.css";

const defaultLabel = [];
class DashboardGA extends Component {
    state = { 
        isSignedIn: false,
        isLoading: false,
        gaReportData: [],
        startDate: moment(new Date()).subtract(1, "Y").format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        totalActiveUsers: 0,
        activeUsersData: {defaultLabel, datasets: []},
        screenPageViewsData: {defaultLabel, datasets: []},
        deviceCategoryData: {defaultLabel, datasets: []},
        crashFreeUsers: 0,
        wauPerMau: 0,
        tabValue: "1",
        activeUsersPlainData: [],
        screenPageViewPlainData: [],
    } 

    // defaultLabel = [];

    colors = () => {
      return [
        'rgba(28, 254, 156, 0.7)',
        'rgba(244, 182, 232, 0.7)',
        'rgba(88, 224, 68, 0.7)',
        'rgba(13, 133, 22, 0.7)',
        'rgba(66, 21, 49, 0.7)',
        'rgba(15, 61, 144, 0.7)',
        'rgba(63, 71, 53, 0.7)',
        'rgba(110, 47, 103, 0.7)',
        'rgba(251, 143, 5, 0.7)',
        'rgba(230, 65, 106, 0.7)',
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
      ];
    };

    gaReportActiveusers = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          },
        ],
        dimensions: [
          {
            name: 'city',
          },
          {
            name: 'country',
          },
        ],
        metrics: [
          {
            name: 'activeUsers',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORT", response.data);

          var totalActiveUsers = 0;
          var dataActiveUsers = [];
          var labels = [];
          var data = [];
          var i = 1;
          var plainData = [];
          response.data.rows.forEach(element => {
            
            // dataActiveUsers.push(
            //   {
            //       label: element.dimensionValues[0].value,
            //       data: element.metricValues[0].value,
            //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
            //   }
            // );
            if(i <= 10){
              data.push(element.metricValues[0].value);
              labels.push(element.dimensionValues[0].value);
              plainData.push({dimension: element.dimensionValues[0].value, metric: element.metricValues[0].value});
              ++i;
            }
            
            totalActiveUsers += parseInt(element.metricValues[0].value);
          });

          dataActiveUsers.push(
            {
                label: "",
                data: data,
                backgroundColor: this.colors(),
            }
        );

          this.setState({isLoading: false, activeUsersPlainData: plainData, totalActiveUsers: totalActiveUsers, gaReportData: response.data.rows, activeUsersData: {labels, datasets: dataActiveUsers}});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gaReportScreenPageViews = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          },
        ],
        dimensions: [
          {
            name: 'unifiedScreenName',
          },
          // {
          //   name: "engagementTime"
          // }
        ],
        metrics: [
          {
            name: 'screenPageViews',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORTscreenPageViews", response.data);

          var dataScreenPageViews = [];
          var labels = [];
          var data = [];
          var i = 1;
          var plainData = [];
          response.data.rows.forEach(element => {
            if(i <= 10){
              data.push(element.metricValues[0].value);
              labels.push(element.dimensionValues[0].value);
              plainData.push({dimension: element.dimensionValues[0].value, metric: element.metricValues[0].value});
              ++i;
            }
          });

          dataScreenPageViews.push(
            {
                label: "",
                data: data,
                backgroundColor: this.colors(),
            }
        );

          this.setState({isLoading: false, screenPageViewPlainData: plainData, screenPageViewsData: {labels, datasets: dataScreenPageViews}});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareportDeviceModels = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          },
        ],
        dimensions: [
          {
            name: 'deviceCategory',
          }
        ],
        metrics: [
          {
            name: 'activeUsers',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORTdeviceCategory", response.data);

          var deviceCategory = [];
          var labels = [];
          var data = [];
          response.data.rows.forEach(element => {
            data.push(element.metricValues[0].value);
            labels.push(element.dimensionValues[0].value);
          });

          deviceCategory.push(
            {
                label: "",
                data: data,
                backgroundColor: this.colors(),
                borderColor: this.colors(),
                borderWidth: 1
            }
        );

          this.setState({isLoading: false, deviceCategoryData: {labels, datasets: deviceCategory}});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareportCrashFreeUsers = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          },
        ],
        dimensions: [
          // {
          //   name: 'deviceCategory',
          // }
        ],
        metrics: [
          {
            name: 'crashFreeUsersRate',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORTCrashFree", response.data);

          var crashFreeValue = 0;
          response.data.rows.forEach(element => {
            crashFreeValue = element.metricValues[0].value*100;
          });

          this.setState({isLoading: false, crashFreeUsers: crashFreeValue});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };

    gareportWauPerMau = () => {
      const params = {
        dateRanges: [
          {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          },
        ],
        dimensions: [
          // {
          //   name: 'deviceCategory',
          // }
        ],
        metrics: [
          {
            name: 'wauPerMau',
          },
        ],
      };
  
      console.log("GAREPORT_PARAMS", params);

      this.setState({isLoading: true}, () => {
        axios
        .post(
          Api.gaReport(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("GAREPORTwauPerMauValue", response.data);

          var wauPerMauValue = 0;
          response.data.rows.forEach(element => {
            wauPerMauValue = element.metricValues[0].value*100;
          });

          this.setState({isLoading: false, wauPerMau: wauPerMauValue});
          
        })
        .catch((error) => {
          console.error("There was an error!", error);
          this.setState({isLoading: false});
        });
      });
      
    };
    
    componentDidMount(){

      ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ArcElement,
        Title,
        Tooltip,
        Legend
      );

        this.gaReportActiveusers();
        this.gaReportScreenPageViews();
        this.gareportDeviceModels();
        this.gareportCrashFreeUsers();
        this.gareportWauPerMau();
    }

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, () => {
        this.gaReportActiveusers(); 
        this.gaReportScreenPageViews();
        this.gareportDeviceModels();
        this.gareportCrashFreeUsers();
      });
    }

    dataTableColumns = () => {
      return [
          {
              name: "City",
              selector: row => row.dimensionValues[0].value,
              sortable: true
          },
          {
              name: "Country",
              selector: row => row.dimensionValues[1].value,
              sortable: true
          },
          {
              name: "Count",
              selector: row => row.metricValues[0].value,
              sortable: true
          }
      ];
    }

    optionsActiveUsers = {
      responsive: true,
      // indexAxis: 'y',
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Top 10 Active Users',
        },
      },
  };
  optionsScreenPageViews = {
      responsive: true,
      // indexAxis: 'y',
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Top 10 Screen Page Views',
        },
      },
  };
  optionsDeviceCategory = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Device category',
        },
      },
  };

  handleTabChange = (event, newValue) => {
    this.setState({tabValue: newValue});
  }

    render() { 
      return (
        <div className="rounded" style={{padding: 5}}>
          <div className="row">
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                  <div className="container text-center rounded" style={{backgroundColor: "#e0e1e3", padding: "10px"}}>
                    <h3 className='text-primary'>
                      {this.state.totalActiveUsers}
                    </h3>
                    <strong>Active Users</strong>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 text-center rounded">
                  <div className="container text-center rounded" style={{backgroundColor: "#e0e1e3", padding: "10px"}}>
                    <h3 className='text-primary'>
                      {this.state.crashFreeUsers.toFixed(2).toString().replace(".00", "")}%
                    </h3>
                    <strong>Crash free users</strong>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 text-center rounded">
                  <div className="container text-center rounded" style={{backgroundColor: "#e0e1e3", padding: "10px"}}>
                    <h3 className='text-primary'>
                      {this.state.wauPerMau.toFixed(2)}%
                    </h3>
                    <strong>Wau per Mau</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <MyDateRangePicker onDateChanged={this.handleDateChanged}/>
            </div>
          </div>

          {/* <Paper style={{padding: 10}}> */}

          {
            this.state.isLoading ? (<LinearProgress color="primary" style={{marginTop: 0}} />) : (<></>)
          }
            
            {/* <DataTable 
                title={"Active Users PosSaku"} 
                data={this.state.gaReportData} 
                columns={this.dataTableColumns()} 
                // options={this.options}
                pagination
                progressPending={this.state.isLoading}
            /> */}
          {/* </Paper> */}
          <br /><br />

          <div className="row">
            <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
              <div style={{padding: "10px"}}>
                <div className="row">
                  <div className='col-10'><strong>City</strong></div>
                  <div className='col-2'><strong>Users</strong></div>
                </div>
                <hr />
                  {this.state.activeUsersData.datasets[0] !== undefined ? (
                    <div>
                      {this.state.activeUsersPlainData.map((row) => (
                          <div className='row'>
                            <div className='col-10'>
                              {row.dimension}
                            </div>
                            <div className='col-2'>{row.metric}</div>
                          </div>
                        ))}
                    </div>
                  ) : (<></>)}
              </div>
            </div>
            <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12">
              <Paper style={{padding: 10}}>
                {
                  this.state.activeUsersData.datasets !== undefined ? (
                    <Bar options={this.optionsActiveUsers} data={this.state.activeUsersData} style={{maxHeight: 300}}/>
                  ) : (<></>)
                }
              </Paper>
            </div>
          </div>
          <br />
          <hr />
          <br />
          {/* Screen views Start*/}
            <div className="row">
              <div className="col-md-7 col-lg-7 col-sm-12 col-xs-12">
              <Paper style={{padding: 10, marginTop: 25}}>
              {
                this.state.screenPageViewsData.datasets !== undefined ? (
                  <Bar options={this.optionsScreenPageViews} data={this.state.screenPageViewsData} style={{maxHeight: 250}} />
                ) : (<></>)
              }
              </Paper>
              </div>
              <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                <div style={{padding: "10px"}}>
                    <div className="row">
                      <div className='col-10'><strong>Screen</strong></div>
                      <div className='col-2'><strong>Views</strong></div>
                    </div>
                    <hr />
                      {this.state.screenPageViewsData.datasets[0] !== undefined ? (
                        <div>
                          {this.state.screenPageViewPlainData.map((row) => (
                              <div className='row'>
                                <div className='col-10'>
                                  {row.dimension}
                                </div>
                                <div className='col-2'>{row.metric}</div>
                              </div>
                            ))}
                        </div>
                      ) : (<></>)}
                  </div>
                </div>
              </div>
          {/* Screen views End*/}
          
          <br />
          
          <br />
          <Paper style={{padding: 10}}>
            {
              this.state.deviceCategoryData.datasets !== undefined ? (
                <Doughnut options={this.optionsDeviceCategory} data={this.state.deviceCategoryData} style={{maxHeight: 250}}/>
              ) : (<></>)
            }
          </Paper>
        </div>
      );
    }
}
 
export default DashboardGA;

function MyDateRangePicker(props){
  const [dateRange, setDateRange] = React.useState([moment(new Date()).subtract(1, "Y").toDate(), Date.now()]);
  const [startDate, endDate] = dateRange;
  const [message, setMessage] = React.useState("");
  return (
    <>
      <ReactDatePicker
        readOnly={false}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        dateFormat="d-M-yyyy"
        onChange={(update) => {
          console.log("RANGE", update);
          
          if(update[0] !== null && update[1] !== null){
            let startDate = moment(update[0]);
            let endDate = moment(update[1]);
            let diffs = Math.abs(startDate.diff(endDate, "days"));
            console.log("DIFFS", diffs);
            // if(diffs > 35){
            //   setDateRange([null, null]);
            //   setMessage("Rentang tanggal max 35 hari");
            //   return
            // }
          }
          
          setDateRange(update);
          setMessage("");
          if(update[0] !== null && update[1] !== null){
            props.onDateChanged(update);
          }

        }}
        isClearable={false}
        popperPlacement="top-end"
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [5, 10],
            },
          },
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
        style={{borderColor: "#FFFFFF"}} 
        wrapperClassName="my-datepicker"
      />
      <div className="text-danger">
        {message}
      </div>
    </>
  );
}