import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { AssignmentReturned, ImportExport, Widgets } from "@mui/icons-material";
import moment from "moment";
import { Button } from '@mui/material';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadTemplateUsahaBulk extends Component {

    getDatas = () => {
        let leadsData = [{
            username: "dummy",
            fullname: "dummy",
            ussd: "dummy",
            email: "dummy",
            pin: 123456,
            avatar: "dummy",
            role: "dummy",
            google_id: "active",
            fb_id: "dummy",
            device_id: "dummy",
            nama_toko: "dummy",
            alamat: "dummy",
            jenis_usaha: "dummy",
            lokasi: "dummy",
            cashier: "dummy"
        }];
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<Button variant='contained' color='primary'><Widgets />&nbsp; Download Template</Button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getDatas} name="Sheet 1">
                    <ExcelColumn label="username" value="username"/>
                    <ExcelColumn label="fullname" value="fullname"/>
                    <ExcelColumn label="ussd" value="ussd"/>
                    <ExcelColumn label="email" value="email"/>
                    <ExcelColumn label="pin" value="pin"/>
                    <ExcelColumn label="avatar" value="avatar"/>
                    <ExcelColumn label="role" value="role" />
                    <ExcelColumn label="google_id" value="google_id" />
                    <ExcelColumn label="fb_id" value="fb_id" />
                    <ExcelColumn label="device_id" value="device_id" />
                    <ExcelColumn label="nama_toko" value="nama_toko" />
                    <ExcelColumn label="alamat" value="alamat" />
                    <ExcelColumn label="jenis_usaha" value="jenis_usaha" />
                    <ExcelColumn label="lokasi" value="lokasi" />
                    <ExcelColumn label="cashier" value="cashier" />
                                {/* //   value={(col) => moment(col.created_at).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/> */}
                    {/* <ExcelColumn label="Downline" value={(col) => col.downline.length}/> */}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default DownloadTemplateUsahaBulk;