import { TextField } from '@mui/material';
// import { DropzoneArea } from 'material-ui-dropzone';
import React, { Component } from 'react'

class TabComponentUploadCsv extends Component {
    state = { 
        files: [],
     }

     handleSaveFile(files){
        console.log("FILES : ", files);
        this.setState({
            files: files,
        });
    }

    render() { 
        return ( 
            <>
            <br /><br />
            <div className="">
            {/* <DropzoneArea
                onChange={this.handleSaveFile.bind(this)}
            /> */}
            </div>
            <br />
            <div className="text-right">
                <div className="btn btn-primary">Simpan</div>
            </div>
            </>
         );
    }
}
 
export default TabComponentUploadCsv;