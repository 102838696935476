import React, { Component } from 'react';
import "./DashBoardUserManagemet.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import moment from "moment";
import Drawer from '@mui/material/Drawer';
import CloseRounded from '@mui/icons-material/CloseRounded';
import DataTable from 'react-data-table-component';
import { Snackbar, TextField } from '@mui/material';
import { Send, Add } from '@mui/icons-material';
import UserInfo from './UserInfo';
import SendNotification from './SendNotification';
import PendingNotification from './PendingNotification';
import ExportDataUser from './ExportDataUser';
import DateRangePicker from '../../lib/DateRangePicker';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

class DashboardUserManagement extends Component {
    state = { 
        columns : [
            {
                name: "USSD",
                selector: row => row.ussd,
                sortable: true
            },
            {
                name: "Nama",
                selector: row => row.fullname,
                sortable: true
            },
            {
                name: "Role",
                selector: row => row.role,
                sortable: true
            },
            {
                name: "Subscription",
                selector: row => row.stores !== undefined && row.stores.length > 0 ? row.stores[0].subscribe_type : "Free",
                sortable: true
            },
            // {
            //     name: "Referral",
            //     selector: row => row.referral,
            //     sortable: true
            // },
            {
                name: "Join Date",
                selector: row => row.created_at,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.created_at).unix();
                    const b = moment(rowB.created_at).unix();

                    return a >= b ? 1 : -1
                    // return parseInt(a) > parseInt(b) ? 1 : -1

                }
            },
            {
                name: "Last Activity",
                selector: row => row.last_activity_date,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.last_activity_date).unix();
                    const b = moment(rowB.last_activity_date).unix();

                    return a >= b ? 1 : -1
                    // return parseInt(a) > parseInt(b) ? 1 : -1

                }
            },
            {
                name: "Action",
                selector: row => row.action
            }
        ],
        data : [], 
        top: false,
        left: false,
        bottom: false,
        right: false,
        selectedUser : {},
        //all, owner store, cashier
        dialogMode: this.DIALOG_MODE_USER_INFO,
        role: "all",
        isLoading: false,
        keyword: "",
        field: "referal_code",
        listPendingNotifications: [],
        // startDate: moment(new Date()).format("YYYY-MM-DD"),
        // endDate: moment(new Date()).format("YYYY-MM-DD"),
        startDate: null,
        endDate: null,
        snackbarOpen: false,
        snackbarMessage: ""
    }

    DIALOG_MODE_USER_INFO = "userInfo";
    DIALOG_MODE_KIRIM_NOTIFIKASI = "kirimNotifikasi";
    DIALOG_MODE_PENDING_NOTIFIKASI = "pendingNotifikasi";

    options = {
        filterType: 'checkbox',
    };

    loadUsers = (role) => {

        if(role == null | role === undefined | role === "all"){
            role = "";
        }
                
        const params = {
            filter: {
                $or: [
                    {
                        fullname: {
                            $regex: this.state.keyword,
                            $options: "i"
                        }
                    },
                    {
                        ussd: this.state.keyword
                    },
                    {
                        referal_code: this.state.keyword
                    }
                ],
                
            },
            sort: {
                created_at: -1
            },
            limit: 2000
        }

        if(this.state.startDate !== null && this.state.endDate !== null){
            params.filter.created_at = {$gte: this.state.startDate, $lte: this.state.endDate};
        }

        if(role !== null && role !== undefined && role !== "all" && role !== ""){
            params.filter.role = role;
        }
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.getUserList(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("USERSRESPONSE", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            responseData.data.forEach(element => {
                // console.log(element);
                const userData = {
                    ussd: element.ussd, 
                    fullname: element.fullname, 
                    created_at: moment(element.created_at).format("DD-MM-YYYY hh:mm:ss"), 
                    last_activity_date:  element.last_activity_date !== undefined ? moment(element.last_activity_date).format("DD-MM-YYYY hh:mm:ss") : null,
                    referral: element.referal_code,
                    role: element.role,
                    stores: element.stores,
                    // element.store_id,
                    action: this.action(element)
            };
                userDatas.push(userData);
            });

            this.setState({data: userDatas, isLoading: false});
            
            
          }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
    };

    deleteNotification = (notification) => {
        console.log("NOTIFICATION", notification);
        // return;
        axios
        .delete(
                Api.deleteNotification(notification._id),
                Api.getRequestHeader()
        )
        .then((response) => {
            console.log(response.data);

            
            if(response.data.success === true){
                this.setState({snackbarOpen: true, snackbarMessage: response.data.data}, () => {
                    this.loadPendingNotification();
                });
                
            }else{
                this.setState({snackbarOpen: true, snackbarMessage: response.data.error}, () => {});
            }

        }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({snackbarOpen: true, snackbarMessage: error.response.data.error}, () => {});
        });
    }

    loadPendingNotification = () => {

        const params = {
            filter :{},
            sort : {"_id":1},
            limit : 50
        }

        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        // this.setState({}, () => {
            axios
          .post(
            Api.listScheduledNotification(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("PENDINGNOTIFICATIONS", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            responseData.messages.forEach(element => {
                userDatas.push(element);
                // console.log(element);
            //     const userData = {
            //         ussd: element.ussd, 
            //         fullname: element.fullname, 
            //         created_at: moment(element.created_at).format("DD-MM-YYYY hh:mm:ss"), 
            //         last_activity_date:  element.last_activity_date !== undefined ? moment(element.last_activity_date).format("DD-MM-YYYY hh:mm:ss") : null,
            //         referral: element.referal_code,
            //         role: element.role,
            //         // element.store_id,
            //         action: this.action(element)
            // };
            //     userDatas.push(userData);
            });

            this.setState({listPendingNotifications: userDatas});
            
            
          }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        // });
        
    };

    componentDidMount() {
        this.loadUsers();
        this.loadPendingNotification();
    }

    toggleDrawer = (open, userInfo, mode) => (event) => {
        console.log(open, event);
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }

        this.setState({right: open, selectedUser: userInfo, dialogMode: mode }, ()=>{
            console.log(this.state.dialogMode)
            if(this.state.dialogMode === this.DIALOG_MODE_PENDING_NOTIFIKASI){
                this.loadPendingNotification();
            }
        });
    };

    action = (userInfo) => {      
        return (<span className="btn btn-primary" onClick={this.toggleDrawer(true, userInfo, this.DIALOG_MODE_USER_INFO)}>Info</span>);
    }

    list(){
        return JSON.stringify(this.state.selectedUser) === "{}" ? (<div></div>) :  (
        <div className="info" role="presentation"
        //   onClick={this.toggleDrawer(anchor, false)}
        //   onKeyDown={this.toggleDrawer(anchor, false)}
        >
          <div className="container">
              <div className="row">
                  <div className="col-11">
                      <h1>Detail Informasi</h1>
                  </div>
                  <div className="col-1">
                      <CloseRounded onClick={this.toggleDrawer(false, {}, this.DIALOG_MODE_USER_INFO)} />
                  </div>
              </div>
              <br /><br />
                <div className="text-center">
                    <img src="https://picsum.photos/id/1/200/300" className="avatar" alt=""/>
                </div>
              <br />
                <div className="row row-padding">
                    <div className="col-4">Nama</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.fullname}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Id User</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.ussd}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Phone Number</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.ussd}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Tipe Akun</div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.role}</strong>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4"></div>
                    <div className="col-8 text-right">
                        <strong>{this.state.selectedUser.stores[0].nama_toko}</strong> - {this.state.selectedUser.stores[0].jenis_usaha}
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-4">Login Terakhir</div>
                    <div className="col-8 text-right">
                        <strong>{moment(this.state.selectedUser.created_at).format("LLL")}</strong>
                    </div>
                </div>

                <div className="text-center">
                    <br />
                <span className="btn btn-primary btn-rounded">
                    Non Aktifkan
                </span>
                </div>
          </div>
        </div>
      );
    }

    getFilterClasses = (role) => {
        let classes = "col-4";
        if(role === this.state.role){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    handleChangeFilter = (role) => {
        console.log("ROLE : ", role);
        this.setState({role: role});
        this.loadUsers(role);
    }

    handleKeywordChange = (event) => {
        this.setState({keyword: event.target.value});
    }

    handleFieldChange = (event) => {
        this.setState({field: event.target.value});
    }

    handleDateChanged = (rangeDate) => {

        let startDate = null;
        let endDate =  null;

        if(rangeDate[0] !== null && rangeDate[1] !== null){
            startDate = moment(rangeDate[0]).format("YYYY-MM-DD");
            endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");
        }
  
        console.log("START : ", startDate, ", END : ", endDate);
  
        this.setState({ startDate: startDate, endDate:  endDate}, () => {this.loadUsers()});
      }

    render() { 
        return ( 
            <div>
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{}, this.DIALOG_MODE_USER_INFO)}
                    >
                    {
                        this.state.dialogMode === this.DIALOG_MODE_USER_INFO ? 
                        (
                            <UserInfo
                                onCloseDrawer={() => this.toggleDrawer(false,{}, this.DIALOG_MODE_USER_INFO)}
                                selectedUser={this.state.selectedUser}
                            />) : (<></>)
                    }

                    {
                        this.state.dialogMode === this.DIALOG_MODE_KIRIM_NOTIFIKASI ? 
                        (
                            <SendNotification
                                onCloseDrawer={() => this.toggleDrawer(false,{}, this.DIALOG_MODE_USER_INFO)}
                                selectedUser={this.state.selectedUser}
                            />) : (<></>)
                    
                    }
                    {
                        this.state.dialogMode === this.DIALOG_MODE_PENDING_NOTIFIKASI ? 
                        (
                            <PendingNotification
                                onCloseDrawer={() => this.toggleDrawer(false,{}, this.DIALOG_MODE_USER_INFO)}
                                selectedUser={this.state.selectedUser}
                                pendingNotificationsData={this.state.listPendingNotifications}
                                onDeleteNotification={this.deleteNotification}
                            />) : (<></>)
                    }
                  </Drawer>
                </React.Fragment>
              ))}

                <div className="container-fluid no-padding">
                    <div className="container-fluid header-page">
                        <div className="row">
                            <div className="col-6">
                                <div className='row' style={{border: "solid 1px #dededd", padding: "0px",  maxWidth: "320px", borderRadius: "10px"}}>
                                    <div className="col-1" style={{maxWidth: "20px", padding: "5px"}}>
                                        <Send style={{color: "#2196f3"}} />
                                    </div>
                                    <div className="col-9" style={{padding: "5px", marginLeft: "10px", cursor: "pointer"}} onClick={this.toggleDrawer(true,{}, this.DIALOG_MODE_PENDING_NOTIFIKASI)}>
                                        <div className="row">
                                            <div className="col-10" style={{color: "#2196f3"}}>
                                                Kirim  Pesan
                                            </div>
                                            <div className="col-1">
                                                <div className="badge badge-danger">{this.state.listPendingNotifications.length}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={this.toggleDrawer(true,{}, this.DIALOG_MODE_KIRIM_NOTIFIKASI)} className="col-1 text-center" style={{cursor: "pointer",backgroundColor: "#2196f3",maxWidth: "32px", color: "#FFFFFF", padding: "5px", marginLeft: "17px", borderRadius: "0 10px 10px 0"}}>
                                        <Add />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 text-right">
                                <ExportDataUser userData={this.state.data}/>
                            </div>
                        </div>
                        
                        {/* <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                                <h2 className="mt-4">User Management</h2>
                            </div>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                <p className="mt-4">
                                Sebagai halaman monitoring dan manajemen pengelolaan<br />user dari aplikasi<br />
                                </p>
                            </div>
                        </div> */}
                    </div>
                    <br />
                    <div className="container-fluid dashboard-content">
                        <div className="row">
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                <div className="row">
                                    {/* <div className="col-1" style={{paddingTop: 10}}><strong>Filter</strong></div> */}
                                    <div className="col-6">
                                        <TextField 
                                            onChange={this.handleKeywordChange}
                                            id="txt-keyword" 
                                            value={this.state.keyword} 
                                            fullWidth 
                                            label="Keyword" variant="outlined" 
                                            size='small'
                                        />
                                    </div>
                                    {/* <div className="col-3">
                                        <DateRangePicker />
                                    </div> */}
                                    {/* <div className="col-4">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Kolom</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.field}
                                                label="Age"
                                                onChange={this.handleFieldChange}
                                            >
                                                <MenuItem value="nama">Nama</MenuItem>
                                                <MenuItem value="ussd">No HP</MenuItem>
                                                <MenuItem value="referal_code">Kode Referal</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div> */}
                                    <div className="col-4">
                                        <DateRangePicker onDateChanged={this.handleDateChanged} />
                                        {/* <div style={{marginTop: 0}}><ExportDataUser userData={this.state.data}/></div> */}
                                    </div>
                                    <div className="col-2">
                                        <div className="btn btn-primary btn-block" style={{marginTop: 0}} onClick={() => this.loadUsers()}>Filter</div>
                                    </div>
                                </div>
                                {/* <br /> */}
                                {/* {this.state.role === "all" ? "Semua" : this.state.role === "owner store" ? "Pemilik" : "Kasir"} */}
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-8 col-xs-12">
                                <div className="row filter-container">
                                    <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                        Semua
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("owner store")} className={this.getFilterClasses("owner store")}>
                                        Pemilik
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("cashier")} className={this.getFilterClasses("cashier")}>
                                        Kasir
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <DataTable 
                            title={"Daftar Pengguna PosSaku"} 
                            data={this.state.data} 
                            columns={this.state.columns} 
                            options={this.options} 
                            // selectableRows
                            pagination
                            progressPending={this.state.isLoading}
                            // progressComponent={<div>Loading</div>}
                        />

                    </div>
                </div>
                <Snackbar
                    open={this.state.snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => {this.setState({snackbarOpen: false})}}
                    message={this.state.snackbarMessage}
                    // action={action}
                    />
            </div>
        );
    }
}
 
export default DashboardUserManagement;