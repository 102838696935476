import { Document, Image, PDFViewer, Page, Text } from '@react-pdf/renderer';
import moment from 'moment';
import React, { Component } from 'react';

class VoucherPdf extends Component {
    state = {  } 
    render() { 
        return (
            <PDFViewer width={"900px"} height={"600px"} showToolbar={true}>
                <Document>
                    <Page size={"A4"} style={{textAlign: "center", alignItems: "center", paddingTop: "35px", paddingBottom: "35px", paddingLeft: "10px", paddingRight: "10px"}}>
                        <Text>Gift Card</Text>
                        <Image src={this.props.qr} style={{width: "50%", marginTop: "20px", marginBottom: "20px"}} />
                        <Text>{this.props.voucherInfo?.nama}</Text>
                        <Text style={{marginTop: "20px", fontSize: "12px"}}>Masa Berlaku</Text>
                        <Text style={{marginTop: "10px", marginBottom: "10px", fontSize: "12px"}}>
                            {moment(this.props.voucherInfo?.start_date).format("DD/MM/YYYY")} - {moment(this.props.voucherInfo?.end_date).format("DD/MM/YYYY")}
                        </Text>
                        <Text style={{marginTop: "8px", fontSize: "12px", fontWeight: "bold"}}>Untuk</Text>
                        <Text style={{marginTop: "8px", fontSize: "12px", fontWeight: "bold"}}>{this.props.voucherInfo?.kuota} pengguna</Text>
                        <Text style={{marginTop: "40px", fontWeight: "bold"}}>PosSaku</Text>
                        <Text style={{marginTop: "10px", fontSize: "10px"}}>https://play.google.com/store/search?q=possaku&c=apps</Text>
                    </Page>
                </Document>
            </PDFViewer>
        );
    }
}
 
export default VoucherPdf;