import React, { Component } from 'react';
import "./ReferralManagemet.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import moment from "moment";
import clsx from 'clsx';
import { makeStyles } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseRounded from '@mui/icons-material/CloseRounded';
import DataTable from 'react-data-table-component';
import { FormControlLabel, FormGroup, Paper, Switch, TextField } from '@mui/material';
import md5 from "md5";
import ExportRefererUser from './ExportRefererUser';
import { Add } from '@mui/icons-material';

class QrisProviders extends Component {
    state = { 
        columns : [
            {
                name: "Nama",
                selector: row => row.name,
                sortable: true
            },
            {
                name: "is Bank ?",
                selector: row => row.is_bank ? "Ya" : "Tidak",
                sortable: true
            },
            {
                name: "Logo",
                selector: row => <img src={row.logo} style={{maxWidth: "30px"}} />,
                sortable: true
            },
            {
                name: "Created",
                selector: row => moment(row.created_at).format("d-M-Y"),
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.created_at).toDate();
                    const b = moment(rowB.created_at).toDate();

                    return a > b ? 1 : -1

                }
            },
            {
                name: "Updated",
                selector: row => moment(row.updated_at).format("d-M-Y H:m:s"),
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.updated_at).toDate();
                    const b = moment(rowB.updated_at).toDate();

                    return a > b ? 1 : -1

                }
            },
            {
                name: "Action",
                selector: row => row.referral !== undefined && row.referral !== null && row.referral.length > 0 ? <></> : row.action
            }
        ],
        data : [], 
        top: false,
        left: false,
        bottom: false,
        right: false,
        selectedUser : {
            name: "",
            logo: "",
            is_bank: "",
            id: ""
        },
        //all, owner store, cashier
        role: "all",
        isLoading: false
    }

    defaultSelectedUser = () => {
        return {
            name: "",
            logo: "",
            is_bank: false
        };
    }

    options = {
        filterType: 'checkbox',
    };

    loadUserMarketing = (role) => {

        const params = {
            filter : {},
            sort : {_id: 1},
            limit : 10
        };
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.qrisProviderList(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("QRIS_PROVIDERS", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            responseData.data.forEach(element => {
                element.created_at =  moment(element.created_at).format("YYYY-MM-DD");
                element.action = this.action(element);
                // const userData = {
                //     nama: element.nama, 
                //     no_ktp: element.no_ktp, 
                //     tempat_lahir: element.tempat_lahir,
                //     tanggal_lahir: element.tanggal_lahir,
                //     referal_code: element.referal_code,
                //     downline: element.downline,
                //     created_at: moment(element.created_at).format("YYYY-MM-DD"), 
                //     action: this.action(element)
                // };
                // userDatas.push(userData);
            });

            this.setState({data: responseData.data, isLoading: false});
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      };

    createQrisProvider = (role) => {

        const params = {
            name: this.state.selectedUser.name,
            logo: this.state.selectedUser.logo,
            is_bank: this.state.selectedUser.is_bank
        };
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
        // return;
    
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.qrisProviderAdd(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("CREATE_QRISPROVIDER", response.data);
            
            this.setState({isLoading: false, right: false, selectedUser: this.defaultSelectedUser}, () => {
                this.loadUserMarketing()
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      };

        updateQrisProvider = (role) => {

            const params = {
                name: this.state.selectedUser.name,
                logo: this.state.selectedUser.logo,
                is_bank: this.state.selectedUser.is_bank
            };
        
            console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
            // return;
        
            this.setState({isLoading: true}, () => {
                axios
            .put(
                Api.qrisProviderUpdate(this.state.selectedUser._id),
                params,
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("UPDATE_QRISPROVIDER", response.data);
                
                this.setState({isLoading: false, right: false, selectedUser: this.defaultSelectedUser}, () => {
                    this.loadUserMarketing();
                });
                
            })
            .catch((error) => {
                console.error("There was an error!", error);
                this.setState({isLoading: false});
            });
            });
        
        };

      componentDidMount() {
          this.loadUserMarketing();
      }

      toggleDrawer = (open, userInfo) => (event) => {
          console.log(userInfo);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        
        let defaultInfo = this.defaultSelectedUser();

        this.setState({right: open, selectedUser: !open ? defaultInfo : userInfo });
      };

    action = (userInfo) => {      
        return (
            <div>
                <span className="btn btn-primary" onClick={this.toggleDrawer(true, userInfo)}>Edit</span>&nbsp;
                {/* <span className="btn btn-primary" onClick={this.toggleDrawer(true, userInfo)}>Gen Code</span> */}
            </div>
        );
    }

    handleNamaChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.name = event.target.value;

        this.setState({selectedUser : selectedUserData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    handleIsBankChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.is_bank = event.target.checked;

        this.setState({selectedUser : selectedUserData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    
    handleLogoChange = (event) => {
        let selectedUserData = this.state.selectedUser;
        selectedUserData.logo = event.target.value;

        this.setState({selectedUser : selectedUserData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    

    list(anchor,){
        return (
        <div className="info" role="presentation"
        //   onClick={this.toggleDrawer(anchor, false)}
        //   onKeyDown={this.toggleDrawer(anchor, false)}
        >
          <div className="container">
              <div className="row">
                    <div className="col-11">
                        <h1>QRIS Provider</h1>
                    </div>
                   
                  <div className="col-1">
                      <CloseRounded onClick={() => {this.toggleDrawer(false, {})}} />
                  </div>
              </div>
              {/* <br /><br />
                <div className="text-center">
                    <img src="https://picsum.photos/id/1/200/300" className="avatar" alt=""/>
                </div> */}
              <br />
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleNamaChange} 
                            id="txt_name" 
                            label="Nama" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedUser.name} 
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleLogoChange}
                            id="txt_logo" 
                            label="Logo Url" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedUser.logo} 
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <FormGroup>
                            <FormControlLabel onChange={this.handleIsBankChange} control={<Switch checked={this.state.selectedUser.is_bank}/>} label="Is Bank ?" />
                        </FormGroup>
                    </div>
                </div>
                <hr />
                <div className="text-center">
                    <span className="btn btn-primary" onClick={() => this.state.selectedUser._id !== undefined && this.state.selectedUser._id != null ? this.updateQrisProvider() : this.createQrisProvider()}>
                        {
                             this.state.selectedUser._id !== undefined && this.state.selectedUser._id != null ? "Update" : "Create"
                        }
                    </span>
                </div>
          </div>
          <br />
        </div>
      );
    }

    getFilterClasses = (role) => {
        let classes = "col-4";
        if(role === this.state.role){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    handleChangeFilter = (role) => {
        console.log("ROLE : ", role);
        this.setState({role: role});
        this.loadUserMarketing(role);
    }

    render() { 
        return ( 
            <div>
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{})}
                    >
                    {this.list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}

                <div className="container-fluid no-padding">
                    <div className="container-fluid header-page">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                {/* <h2 className="mt-4">Referral User Management</h2> */}
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-right">
                                {/* <ExportRefererUser data={this.state.data} />&nbsp; */}
                                <span className="btn btn-primary" onClick={this.toggleDrawer(true, {})}><Add /> Add</span>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid dashboard-content">
                        {/* <div className="row">
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                <small>Tampilkan hasil untuk :</small> 
                                <br />
                                {this.state.role === "all" ? "Semua" : this.state.role === "owner store" ? "Pemilik" : "Kasir"}
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-8 col-xs-12">
                                <div className="row filter-container">
                                    <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                        Semua
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("owner store")} className={this.getFilterClasses("owner store")}>
                                        Pemilik
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("cashier")} className={this.getFilterClasses("cashier")}>
                                        Kasir
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <br /> */}
                        <DataTable 
                            title="" 
                            data={this.state.data} 
                            columns={this.state.columns} 
                            options={this.options} 
                            // selectableRows
                            pagination
                            progressPending={this.state.isLoading}
                            // progressComponent={<div>Loading</div>}
                        />

                    </div>
                </div>
            </div>
        );
    }
}
 
export default QrisProviders;