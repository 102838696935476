import React, { Component } from 'react';
import axios from "axios";
import { CloseRounded, ArrowCircleDown, UploadFile, UploadFileRounded, UploadFileOutlined, Image, ImageOutlined } from '@mui/icons-material';
import { Alert, Checkbox, FormControl, FormControlLabel, FormLabel, LinearProgress, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Api from "../../lib/Api";
import { FileUploader } from 'react-drag-drop-files';

class SendNotification extends Component {
    state = { 
        title: "",
        content: "",
        data: "",
        kirimSekarang: 1,
        rutinkan: false,
        simpanFavorit: false,
        tanggalKirim: new Date(),
        filterPengiriman: "",
        isSending: false,
        errorMessage: "",
        isHasImage: false,
        imageUrl: "",
        file: null,
        imageType: "URL",
        successMessage: ""
     } 

    handleResetForm = () => {
        this.setState(
            {title: "", content: "", data: "", kirimSekarang: 1, rutinkan: false, simpanFavorit: false, 
            tanggalKirim: new Date(), errorMessage: "", isHasImage: false, imageUrl: "", file: null, imageType: "URL"})
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value});
    }
    
    handleContentChange = (event) => {
        this.setState({content: event.target.value});
    }
    
    handleDataChange = (event) => {
        this.setState({data: event.target.value});
    }

    handleIsHasImage = (event) => {
        this.setState({isHasImage: event.target.checked});
    }

    handleImageUrlChange = (event) => {
        this.setState({imageUrl: event.target.value});
    }

    handleSendNotification = () => {
        console.log(this.state);
        if(this.state.title === null || this.state.title === undefined || this.state.title === ""){
            this.setState({errorMessage: "Judul belum diisi"});
            return;
        }

        if(this.state.content === null || this.state.content === undefined || this.state.content === ""){
            this.setState({errorMessage: "Isi notifikasi belum diisi"});
            return;
        }

        if(this.state.isHasImage === true && this.state.imageUrl?.length <= 0){
            if(this.state.imageType === "IMAGE" && this.state.file === null){
              this.setState({errorMessage: "File gambar belum dipilih"});  
              return;
            }
            
            if(this.state.imageType === "URL" && this.state.imageUrl?.length <= 0){
                this.setState({errorMessage: "Url gambar belum diisi"});
                return;
            }
        }

        if(this.state.isHasImage && this.state.imageType === "IMAGE"){
            this.uploadImage(this.state.kirimSekarang === 1 ? this.sendNotification : this.sendScheduledNotification);
        }else{
            if(this.state.kirimSekarang === 1){//langsung kirim
                this.sendNotification();
            }else{//kirim nanti
                this.sendScheduledNotification()
            }
        }        

    }

    handleWaktuPengirimanChange = (event) => {
        console.log(event.target.value);
        this.setState({kirimSekarang: event.target.value});
    };

    handleImageTypeChange = (event) => {
        console.log(event.target.value);
        this.setState({imageType: event.target.value});
    };

    handleRutinkanChange = (event) => {
        this.setState({rutinkan: event.target.checked});
    }

    handleSimpanFavoritChange = (event) => {
        this.setState({simpanFavorit: event.target.checked});
    }

    handleTanggalKirimChange = (date) => {
        console.log("TGLKIRIM",date);
        this.setState({tanggalKirim: date});
    }

    handleChangeFillterPengiriman = (filterPengiriman) => {
        this.setState({filterPengiriman: filterPengiriman});
    }

    getFilterButtonClasses(buttonFilterName){
        let classes = "btn";
        classes += buttonFilterName === this.state.filterPengiriman ? " btn-primary" : " btn-outline-primary";
        // console.log("CLASSES : ", classes);
        return classes;
    }

    sendNotification = () => {
        const params = {
            data: {},
            title: this.state.title,
            content: this.state.content
        }

        if(this.state.isHasImage){
            params.data.image_url = this.state.imageUrl;
        }

        if(this.state.filterPengiriman !== ""){
           params.filter = {
            role: this.state.filterPengiriman
           }; 
        }

        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
        // return;
        this.setState({isSending: true}, () => {
        // return;
            axios
          .post(
            Api.sendPushNotification(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("USERSRESPONSE", response.data);
            const responseData = response.data;
            console.log("USERSRESPONSE", response.data, responseData.success);
            
            this.setState({isSending: false, errorMessage: "", successMessage: "Notifikasi berhasil dikirim"}, () => {
                if(responseData.success === true){
                    // this.props.onCloseDrawer();
                    this.handleResetForm();
                }
            });
            
            
          }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({isSending: false, errorMessage: error.message});
          });
        });
        
    };

    sendScheduledNotification = () => {

        const params = {
            data: {},
            title: this.state.title,
            content: this.state.content,
            schedule: {
                date: moment(this.state.tanggalKirim).format("DD/MM/YYYY"),
                time: moment(this.state.tanggalKirim).format("H:mm"),
                repeate: this.state.rutinkan,
                daily: false
            }
        }

        if(this.state.isHasImage){
            params.data.image_url = this.state.imageUrl;
        }

        if(this.state.filterPengiriman !== ""){
           params.filter = {
            role: this.state.filterPengiriman
           }; 
        }

        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
        // return;
        this.setState({isSending: true}, () => {
            // return;
            axios
          .post(
            Api.sendScheduledNotification(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("USERSRESPONSE", response.data);
            const responseData = response.data;
            console.log("USERSRESPONSE", response.data, responseData.success);

            this.setState({isSending: false, errorMessage: "", successMessage: "Notifikasi berhasil dikirim"}, () => {
                if(responseData.success === true){
                    // this.props.onCloseDrawer();
                    this.handleResetForm();
                }
            });
            
            
          }).catch((error) => {
            console.error("There was an error!", error);
            this.setState({isSending: false, errorMessage: error.message});
          });
        });
        
    };

    uploadImage = (callback) => {
        const data = new FormData();
            data.append("photo", this.state.file);

            axios
                .post(Api.uploadImage(), data, Api.getRequestHeader(true))
                .then((response) => {
                    console.log("RESPONSE_AXIOS", response.data);
                    if (response.status === 200 || response.status === 201) {
                        // console.log(
                        //     "RESPONSE_AXIOS",
                        //     response.data.url,
                        //     response.data.succcess
                        // );
                        
                        if (response.data.succcess === true) {
                            this.setState({imageUrl: response.data.url}, () => {
                                callback();
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.setState({
                        isSubmitting: false,
                        errorMessage: error.message,
                    });
                    console.error("There was an error!", error);
                });
    }

    handleFileChange = (file) => {
        console.log(file);
        this.setState({file: file});
    };

    dropArea = () => {
        return (
        <div>
            {
                this.state.file === null && <div className='text-center' style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <ImageOutlined /><br />Browse atau Drag Drop file disini
                </div>
            }

            {
                this.state.file !== null && <div className='text-center' style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <Image className='text-success' /><br />{this.state.file.name}
                </div>
            }
        </div>
        );
    }

    render() { 
        return (
            <div className="container-fluid" style={{minWidth: "500px"}}>
                <br />
                <div className="row">
                    <div className="col-10">
                    <h3>Kirim Notifikasi</h3>
                    </div>
                    <div className="col-2">
                        <div className='text-right'>
                            <CloseRounded onClick={this.props.onCloseDrawer()} style={{cursor: "pointer"}} />
                        </div>
                    </div>
                </div>
                
                
                {/* <h4>Tujuan</h4> */}
                <br />
                {
                    this.state.isSending ? (<div><LinearProgress /><br /></div>) : <></>
                }
                <div>
                    <div 
                        className={this.getFilterButtonClasses("")} 
                        style={{borderRadius: "100px"}}
                        onClick={() => this.handleChangeFillterPengiriman("")}
                    >
                        Semua
                    </div>
                    &nbsp;&nbsp;
                    <div 
                        className={this.getFilterButtonClasses("owner store")} 
                        style={{borderRadius: "100px"}}
                        onClick={() => this.handleChangeFillterPengiriman("owner store")}
                    >
                        Pemilik Toko
                    </div>
                    &nbsp;&nbsp;
                    <div 
                        className={this.getFilterButtonClasses("kasir")} 
                        style={{borderRadius: "100px"}}
                        onClick={() => this.handleChangeFillterPengiriman("kasir")}
                    >
                        Kasir
                    </div>
                </div>
                <br />
                <div className="row">

                    {this.state.errorMessage?.length > 0 && <div className="col-12 text-danger">
                        {
                            this.state.errorMessage.length > 0 && <Alert severity="error" style={{marginBottom: "20px"}}>{this.state.errorMessage}</Alert>
                        }
                        </div>
                    }

                    {this.state.successMessage?.length > 0 && <div className="col-12 text-success">
                        {
                            this.state.successMessage.length > 0 && <Alert severity="success" style={{marginBottom: "20px"}}>{this.state.successMessage}</Alert>
                        }
                        </div>
                    }

                </div>
                {/* <br /> */}
                <div className="row">
                    <div className="col-12 text-danger">
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleTitleChange} 
                            id="txt_title" 
                            label="Judul Notifikasi" 
                            fullWidth 
                            variant="outlined"
                            size='small'
                            value={this.state.title}
                        />
                    </div>
                </div>
                
                <br />
                <div className="row">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleContentChange} 
                            id="txt_content" 
                            label="Isi notifikasi" 
                            fullWidth 
                            variant="outlined" 
                            size='small'
                            value={this.state.content}
                        />        
                    </div>
                </div>
                <br />
                <div>
                        <FormControlLabel control={<Switch checked={this.state.isHasImage} onChange={this.handleIsHasImage} />} label="Sertakan gambar" />
                        
                        {
                            this.state.isHasImage && <div>
                                    <FormControl size="small">
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={this.state.imageType}
                                            onChange={this.handleImageTypeChange}
                                        >
                                            <div className="" style={{display: "flex"}}>
                                                <FormControlLabel value={"URL"} control={<Radio />} label={<small>Url gambar</small>} />
                                                <FormControlLabel value={"IMAGE"} control={<Radio />} label={<small>Upload gambar baru</small>} />
                                            </div>
                                            
                                            
                                        </RadioGroup>
                                    </FormControl>
                            </div>
                        }
                        
                        {
                            this.state.isHasImage && this.state.imageType === "URL" && <TextField 
                                onChange={this.handleImageUrlChange} 
                                id="txt_attachment" 
                                label="Url gambar, ex: https://www.possaku.com/image/sample.jpg" 
                                fullWidth 
                                variant="outlined" 
                                size='small'
                                value={this.state.imageUrl}
                            />
                        }
                        
                        {
                            this.state.isHasImage && this.state.imageType === "IMAGE" && <div>
                                <FileUploader 
                                    classes={"container border rounded"}
                                    children={this.dropArea()} 
                                    multiple={false} 
                                    handleChange={this.handleFileChange}  
                                    types={["JPG", "PNG", "JPEG"]} 
                                />
                            </div>
                        }       
                </div>
                
                {/* <br />
                <div className="row">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleDataChange} 
                            id="txt_data" 
                            label="Data" 
                            fullWidth 
                            variant="outlined" 
                        />        
                    </div>
                </div> */}
                <br />
                <strong>Waktu Pengiriman</strong>
                <div className="row">
                    <div className="col-12">
                        <FormControl size='small'>
                            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={this.state.kirimSekarang}
                                onChange={this.handleWaktuPengirimanChange}
                            >
                                <div className="row">
                                    <div className="col-6">
                                        <FormControlLabel value={1} control={<Radio />} label="Sekarang" />
                                    </div>
                                    <div className="col-6">
                                        <FormControlLabel value={0} control={<Radio />} label="Nanti" />
                                    </div>
                                </div>
                                
                                
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {
                            this.state.kirimSekarang === "0" ? 
                            (
                                <div style={{marginBottom: "10px"}}>
                                    <div className="row">
                                        <div className="col-3">
                                            <FormControlLabel control={<Checkbox onChange={this.handleRutinkanChange} checked={this.state.rutinkan} />} label="Rutinkan" style={{paddingTop: "8px"}} />
                                        </div>
                                        <div className="col-9">
                                            <span style={{padding: "5px", border: "solid 0px", borderRadius: "10px", fontSize: "12px", cursor: "pointer"}}>
                                                Atur Tanggal & Waktu
                                                {/* &nbsp;&nbsp;<strong>v</strong> */}
                                            </span>
                                            <br />
                                            <ReactDatePicker 
                                                selected={this.state.tanggalKirim} 
                                                showTimeSelect 
                                                dateFormat="yyyy-MM-dd HH:mm:ss" 
                                                onChange={this.handleTanggalKirimChange}
                                            />
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                
                            ) : (<></>)
                        }
                        
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12 text-right">
                        <FormControlLabel control={<Checkbox onChange={this.handleSimpanFavoritChange} checked={this.state.simpanFavorit} />} label="Simpan favorit" />
                        {
                            this.state.isSending ? 
                            (<></>) : 
                            (
                                <div onClick={this.handleSendNotification} className="btn btn-primary" style={{borderRadius: "50px", paddingLeft: "30px", paddingRight: "30px"}}>Kirim</div>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}
 
export default SendNotification;