import React, { Component } from 'react';
import "./DashBoardOverview.css";
import Api from "../../lib/Api";
import axios from "axios";
import { Circle } from '@mui/icons-material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { isContentEditable } from '@testing-library/user-event/dist/utils';
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';

class DashboardOverview extends Component {
    state = { 
        totalToko: 0,
        totalPengguna: 0,
        totalSalesProduct: 0,
        topEtalase: [],
        topEtalaseSummaryLainnya: 0,
        tickFormatTokoTerlaris: [],
        tickValueTokoTerlaris: [],
        dataTokoTerlaris: [],
        tickFormatTopTransaksi: [],
        tickValueTopTransaksi: [],
        dataTokoTransaksiTerbanyak: [],
        labelTokoTerlaris: [],
        dataUser: {
            owner: 0,
            karyawan: 0
        },
        selectedFilterPeriod: "TODAY",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
     }

    loadTopStatistics = () => {
        const params = {
            // filter:{store_id: "possax" },
            // sort:{nama: 1},
            // limit:100
        };
    
        console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .get(Api.topStatistics())
          .then((response) => {
            console.log("TOPSTATISTICS", response.data);
            const responseData = response.data;
            
            var totalToko = responseData.countStore;
            var totalPengguna = responseData.countUser;
            var totalSalesProduct = 0;
            var topEtalaseSummaryLainnya = 0;

            responseData.countSalesItem.forEach(sales => {
                totalSalesProduct += sales.count;
            });

            for(var i=0; i<responseData.topEtalase.length; i++){
                if(i > 2){
                    topEtalaseSummaryLainnya += responseData.topEtalase[i].count;
                }
            }

            var dataToko = [];
            var i = 0;
            responseData.topTransaction.forEach(element => {
                i += 1;
                // console.log("STORE ",i, store);
                if(i <= 5){
                    dataToko.push(
                        {
                            tickValue: i,
                            tickFormat: element.storess[0].nama_toko,
                            data: [
                                {quarter: i, earnings: element.count},
                            ]
                        }
                    );
                }
                
            });

            var tickFormat = [];
            var tickValue = [];
            i = 0;
            dataToko.map((data) => {
                tickFormat.push(data.tickFormat.replace(" ", "\n"));
                tickValue.push(++i);
            });

            // this.setState({dataTokoTerlaris: dataToko, tickValue: tickValue, tickFormat: tickFormat}, ()=>{
            //     console.log("DATA_TOKO",this.state.dataTokoTerlaris);
            // });

            console.log("TOPETALASE : ", responseData.topEtalase)
            this.setState({
                totalToko: totalToko, 
                totalPengguna: totalPengguna, 
                totalSalesProduct: totalSalesProduct, 
                topEtalase: responseData.topEtalase,
                topEtalaseSummaryLainnya: topEtalaseSummaryLainnya,
                // dataTokoTerlaris: dataToko, 
                tickValue: tickValue, 
                tickFormat: tickFormat
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadTokoTerlaris = () => {
        const params = {
            match:{},
            sort:{_id: -1},
            limit:5
        };
    
        console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
        .post(
            Api.topStores(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("SHOWLOG_TOKOTERLARIS_RESPONSE", response.data);
            const responseData = response.data;
            
            var dataToko = [];
            var labels = [];
            var i = 0;
            responseData.data.forEach(store => {
                i += 1;
                if(i <= 10){
                    dataToko.push(store.totalTransaksi/1000);
                    labels.push(store.nama_toko);
                }
            });

            this.setState({dataTokoTerlaris: {labels, datasets: [{label: "", data: dataToko, backgroundColor: this.colors()}]}}, () => {
                console.log("SHOWLOG_TOKOTERLARIS",this.state.dataTokoTerlaris)
            });

          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    loadTokoTransaksiTerbanyak = () => {
        const params = {
            match:{},
            sort:{totalTransaksi: -1},
            limit:0
        };
    
        console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
        .post(
            Api.topStores(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("SHOWLOG_TOKOTRANTERBANYAK_RESPONSE", response.data);
            const responseData = response.data;
            
            var dataToko = [];
            var labels = [];
            var i = 0;
            responseData.data.forEach(store => {
                i += 1;
                // console.log("STORE ",i, store);
                if(i <= 10){
                    dataToko.push(store.totalTransaksi/1000);
                    labels.push(store.nama_toko);
                }

                
            });

            console.log("VAR_DATA_TOKO : ", dataToko);
            this.setState({dataTokoTransaksiTerbanyak: {labels, datasets: [{label: "", data: dataToko, backgroundColor: this.colors()}]}}, () => {
                console.log("SHOWLOG_TOKOTRANTERBANYAK",this.state.dataTokoTransaksiTerbanyak)
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    };

    colors = () => {
        return [
          'rgba(28, 254, 156, 0.7)',
          'rgba(244, 182, 232, 0.7)',
          'rgba(88, 224, 68, 0.7)',
          'rgba(13, 133, 22, 0.7)',
          'rgba(66, 21, 49, 0.7)',
          'rgba(15, 61, 144, 0.7)',
          'rgba(63, 71, 53, 0.7)',
          'rgba(110, 47, 103, 0.7)',
          'rgba(251, 143, 5, 0.7)',
          'rgba(230, 65, 106, 0.7)',
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(255, 159, 64, 0.7)',
        ];
      };

      loadUsers = () => {

        // let filter = {"$regex":{role:"owner"}};
        // let filter = {role: "owner store"};

        // let startDate = moment().format("YYYY-MM-DD");
        // let endDate = moment().format("YYYY-MM-DD");

        // let filter = {
        //     created_at: {$gte: startDate, $lte: endDate}
        // };
        // let filter = {
        //     dateStart: this.state.startDate,
        //     dateEnd: this.state.endDate
        // };
        // const params = {
        //     filter:filter,
        //     // sort:{ussd:-1},
        //     sort:{created_at: -1},
        //     limit:100
        // };

        let params = {
            dateStart: this.state.startDate,
            dateEnd: this.state.endDate
        };
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
    
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.countUser(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("LISTOWNER", response.data);
            const responseData = response.data;
            const userDatas = [];
            
            var currentDate = moment().format("YYYYMMDD");
            console.log("HARIINI_currdate", currentDate);

            // let jumlahOwner = responseData.data.filter((element) => element.created_at !== undefined && moment(element.created_at).format("YYYYMMDD") === currentDate && element.role === "owner store").length;
            // let userHariIni = responseData.data.filter((element) => moment(element.created_at).format("YYYYMMDD") === currentDate);
            // let jumlahKaryawan = responseData.data.filter((element) => element.created_at !== undefined &&  moment(element.created_at).format("YYYYMMDD") === currentDate && element.role !== "owner store").length;
            
            let jumlahOwner = responseData.countOwner.length > 0 ? responseData.countOwner[0].COUNT : 0;
            let jumlahKaryawan = responseData.countKasir.length > 0 ? responseData.countKasir[0].COUNT : 0;

            this.setState({dataUser: {owner: jumlahOwner, karyawan: jumlahKaryawan}}, () => console.log("DATAUSER",this.state.dataUser));
            // console.log("USERHARIINI", userHariIni);
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      }

    handleChangePeriod = (event) => {

        let startDate = moment().format("YYYY-MM-DD");
        let endDate = moment().format("YYYY-MM-DD");
        
        if(event.target.value === "TODAY"){
            startDate = moment().format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
        }else if(event.target.value === "LAST_7_DAYS"){
            startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
        }else if(event.target.value === "LAST_30_DAYS"){
            startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
        }

        this.setState({selectedFilterPeriod: event.target.value, startDate: startDate, endDate: endDate}, () => {
            console.log("PERIOD", event.target.value);
            this.loadUsers();
        });
    }

    componentDidMount() {
        
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend
        );
        this.loadUsers();
        this.loadTopStatistics();
        this.loadTokoTerlaris();
        this.loadTokoTransaksiTerbanyak();

    }

    optionsTerlaris = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Toko Terlaris',
          },
        },
    };

    optionsTransaksiTerbanyak = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Transaksi Terbanyak',
          },
        },
    };

    // getDataSetTerlaris = () => {
    //     return {
    //         labels,
    //         datasets: [
    //           {
    //             label: 'Dataset 1',
    //             data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //           },
    //           {
    //             label: 'Dataset 2',
    //             data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    //             backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //           },
    //         ],
    //       };
    // }

    render() { 
        console.log("RENDER ", this.state.dataTokoTerlaris);
        return ( 

            <div className="container-fluid no-padding">
                <div className="container-fluid header-page">
                    <div className="row">
                        <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12">
                            <h2 className="mt-4">Overview</h2>
                        </div>
                        <div className="col-10 col-md-10 col-lg-10 col-xs-12 col-sm-12">
                            <p className="mt-4">
                            Sebagai halaman awal untuk memonitoring<br />seluruh aktifitas di aplikasi<br />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid dashboard-content">
                    
                    <div className="container rounded-border solid-border">
                        <div className="row">
                            <div className="col-md-2 col-lg-2 top-resume-item text-center">
                                <small className="font-weight-lighter"><strong>Total Toko</strong></small>
                                <h3 style={{marginTop: 15}} className="text-primary"><strong>{this.state.totalToko}</strong></h3>
                            </div>
                            <div className="col-md-2 col-lg-2 top-resume-item text-center">
                                <small className="font-weight-lighter"><strong>Jumlah Pengguna</strong></small>
                                <br />
                                <h3 style={{marginTop: 15}} className="text-primary"><strong>{this.state.totalPengguna}</strong></h3>
                            </div>
                            <div className="col-md-2 col-lg-2 top-resume-item text-center">
                                <small className="font-weight-lighter"><strong>Penjualan Produk</strong></small>
                                <br />
                                <h3 style={{marginTop: 15}} className="text-primary"><strong>{this.state.totalSalesProduct}</strong></h3>
                            </div>
                            <div className="col-6">
                                <small className="font-weight-lighter"><strong>Top Etalase Produk</strong></small>
                                <div className="row">
                                    <div className="col-md-3 col-lg-3">
                                        <small style={{textTransform: "capitalize"}}><Circle style={{fontSize: 9}}/> {this.state.topEtalase.length > 0 ? this.state.topEtalase[0]._id : ""}</small>
                                        <h3>{this.state.topEtalase.length > 0 ? this.state.topEtalase[0].count : ""}</h3>
                                    </div>
                                    <div className="col-md-3 col-lg-3">
                                        <small style={{textTransform: "capitalize"}}><Circle  style={{fontSize: 9}} /> {this.state.topEtalase.length > 1 ? this.state.topEtalase[1]._id : ""}</small>
                                        <h3>{this.state.topEtalase.length > 1 ? this.state.topEtalase[1].count : ""}</h3>
                                    </div>
                                    <div className="col-md-3 col-lg-3">
                                        <small style={{textTransform: "capitalize"}}><Circle  style={{fontSize: 9}} /> {this.state.topEtalase.length > 3 ? this.state.topEtalase[3]._id : ""}</small>
                                        <h3>{this.state.topEtalase.length > 2 ? this.state.topEtalase[2].count : ""}</h3>
                                    </div>
                                    <div className="col-md-3 col-lg-3">
                                        <small style={{textTransform: "capitalize"}}><Circle style={{fontSize: 9}} /> Lainnya</small>
                                        <h3>{this.state.topEtalase.length > 2 ? this.state.topEtalaseSummaryLainnya : ""}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-1"></div> */}
                            <div className="col-md-6 col-lg-6 rounded-border solid-border" style={{padding:10}}>
                                {/* <h5>Toko Terlaris</h5>
                                <hr /> */}
                                {this.state.dataTokoTerlaris.length <= 0 ? 
                                    <div></div> : 
                                    // <div className="solid-border">
                                        <Bar options={this.optionsTerlaris} data={this.state.dataTokoTerlaris} />
                                    // </div>
                                    
                                }
                                
                            </div>
                            <div className="col-1 hidden-sm hidden-xs"></div>
                            <div className="col-md-5 col-lg-5 rounded-border solid-border" style={{padding:10}}>
                                {/* <h5>Transaksi Terbanyak</h5>
                                <hr /> */}
                                {this.state.dataTokoTransaksiTerbanyak.length <= 0 ? 
                                    <div></div> : 
                                    // <div className="solid-border">
                                        <Bar options={this.optionsTransaksiTerbanyak} data={this.state.dataTokoTransaksiTerbanyak} />
                                    // </div>
                                }
                            </div>
                            {/* <div className="col-1"></div> */}
                        </div>
                    </div>

                    <div style={{marginTop: "30px"}}>
                        <div className="row">
                            <div className="col-6">
                                <Paper style={{padding: "10px"}}>
                                    <div className='text-center'>

                                        <strong>
                                            User Baru
                                        </strong>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.selectedFilterPeriod}
                                                label=""
                                                onChange={this.handleChangePeriod}
                                            >
                                                <MenuItem value="TODAY">Today</MenuItem>
                                                <MenuItem value="LAST_7_DAYS">Last 7 days</MenuItem>
                                                <MenuItem value="LAST_30_DAYS">Last 30 days</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <hr />
                                        <div className="row">
                                            <div className='col-6 text-center'><strong>Owner</strong></div>
                                            <div className='col-6 text-center'><strong>Karyawan</strong></div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className='col-6 text-center'>{this.state.dataUser.owner}</div>
                                            <div className='col-6 text-center'>{this.state.dataUser.karyawan}</div>
                                        </div>
                                        {/* {this.state.activeUsersData.datasets[0] !== undefined ? (
                                            <div>
                                            {this.state.activeUsersPlainData.map((row) => (
                                                <div className='row'>
                                                    <div className='col-10'>
                                                    {row.dimension}
                                                    </div>
                                                    <div className='col-2'>{row.metric}</div>
                                                </div>
                                                ))}
                                            </div>
                                        ) : (<></>)} */}
                                    </div>
                                </Paper>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>

                </div>
                
            </div>

         );
    }
}
 
export default DashboardOverview;