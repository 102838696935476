import './App.css';
import { Component } from 'react';
import Login from "./components/Login";
import Global from "./lib/Global";
import MainPage from './components/MainPage';

class App extends Component{
  state = {
    auth: {},
    validAuth: false,
    isSignedIn: false
  };

  componentDidMount() {
    this.initAppState();
    // importScript("https://apis.google.com/js/client:platform.js");
  }

  initAppState = () => {

    window.gapi.load("auth2", this.initGoogleSignin);

    let authData = JSON.parse(localStorage.getItem("auth-possaku"));
    let validAuth = authData && authData.token && authData.token.token.length > 0;

    console.log("AUTHDATA", authData, validAuth);
    Global.add(authData);

    this.setState({ auth: authData, validAuth: validAuth });
  };

  handleAuthChange = () => {
    this.initAppState();
    // this.props.history.push('/');
  };

render() { 
    return ( 
            // <DashboardGA />
            this.state.validAuth  ? 
            (
              <MainPage onAuthChange={this.handleAuthChange}  />
                // <Router>

                //     <Routes>
                        
                //         <Route exact path="/" element={
                //           <MainPage >
                //             <DashboardGA />
                //           </MainPage>
                //         }>
                        
                //             <Dashboard currentPage="overview" onAuthChange={this.handleAuthChange}>
                //                 <DashboardOverview />
                //             </Dashboard>
                //         </Route>

                //         <Route exact path="/user-management">
                //             <Dashboard currentPage="user-management" onAuthChange={this.handleAuthChange}>
                //                 <DashboardUserManagement />
                //             </Dashboard>
                //         </Route>

                //         <Route exact path="/store-management">
                //             <Dashboard currentPage="store-management" onAuthChange={this.handleAuthChange}>
                //                 <DashboardStoreManagement />
                //             </Dashboard>
                //         </Route>

                //         <Route exact path="/products-management">
                //             <Dashboard currentPage="products-management" onAuthChange={this.handleAuthChange}>
                //                 <DashboardProductsManagement />
                //             </Dashboard>
                //         </Route>
                //         <Route exact path="/artikel-tutorial">
                //             <Dashboard currentPage="artikel-tutorial" onAuthChange={this.handleAuthChange}>
                //                 <DashboardArtikelTutorial />
                //             </Dashboard>
                //         </Route>
                //         <Route path="/setting">
                //             <SettingContact />
                //         </Route>
                //     </Routes>
                // </Router>
            ) : 
            (
                <Login onLogin={this.handleAuthChange} />
            )
        
     );
  }
}

// function App() {

//   return (
//     <div className="App">
//       <div className="btn btn-primary">Hellooo</div>
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
