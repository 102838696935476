import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportProducts extends Component {

    getUserDatas = () => {
        let leadsData = [];
        this.props.data.forEach(element => {
            // console.log(element);
            // element.data.forEach(product => {
                leadsData.push(element);
            // });
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary"><ImportExport />Export ke Excel</button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getUserDatas} name="Product Data">
                    <ExcelColumn label="Nama" value="nama"/>
                    <ExcelColumn label="Status" value="status"/>
                    <ExcelColumn label="Etalase" value="kategori"/>
                    {/* <ExcelColumn label="Join Date" value="created_at" /> */}
                                {/* //   value={(col) => moment(col.created_at).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/> */}
                    {/* <ExcelColumn label="Last Activity" value="last_activity_date" /> */}
                                {/* //   value={(col) => moment(col.last_activity_date).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/> */}
                    
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportProducts;