import Global from "./Global";

class Api {

    isProduction = false;
    apiUrl = "https://possaku.store/dev/core/";
    socketUrl = "http://localhost:4001/";
    apiUrlNotification = "https://possaku.store/dev/notification/";
    apiUrlSubscription = "https://possaku.store/dev/subscription/";
    apiUrlArticle = "https://possaku.store/dev/articles/";
    
    // apiUrl = "http://139.180.143.82:4000/";
    // socketUrl = "http://localhost:4001/";
    // apiUrlNotification = "http://139.180.143.82:3000/";
    // apiUrlSubscription = "http://139.180.143.82:2200/";
    // apiUrlArticle = "http://139.180.143.82:4400/";

    
    apiUrlProduction = "https://possaku.store/prod/core/";
    socketUrlProduction = "http://139.180.143.82:4000/";
    apiUrlNotificationProduction = "https://possaku.store/prod/notification/";
    apiUrlSubscriptionProduction = "https://possaku.store/prod/subscription/";
    apiUrlArticleProduction = "https://possaku.store/prod/articles/";
    // apiUrlProduction = "http://54.251.169.232:4000/";
    // socketUrlProduction = "http://139.180.143.82:4000/";
    // apiUrlNotificationProduction = "http://54.251.169.232:3000/";
    // apiUrlSubscriptionProduction = "http://54.251.169.232:2200/";
    // apiUrlArticleProduction = "http://54.251.169.232:4400/";


    // eslint-disable-next-line no-useless-constructor
    constructor() { }

    getRequestHeader(isFormData = false) {
        // console.log("GLOBAL : ", Global.get());
        return {
            headers: {
                "Content-Type": isFormData === false
                    ? "application/json"
                    : "application/x-www-form-urlencoded",
                // "Accept": `application/json`,
                // "Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTc2YjUyN2FiMGNiMGNlOTUwZGQ2OWYxZWJkOWRmMDU0NGVjYTE4MTQ3MDg0OWY0YmRlZmUxMDkzYjRmNGJiNTcyYzQ0ZDE4Zjk5MWU3ZDIiLCJpYXQiOjE2MDk3Mjc1MDAsIm5iZiI6MTYwOTcyNzUwMCwiZXhwIjoxNjQxMjYzNTAwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.vaBxznomz3Z5_HpiRZ5J_Z3PNeKj57APwx_w66IorcmJVI_bVD1gBodFM_nhX6p6LdSJmkX1Eah64WIBJJiZ__emsN54XLt59BHHvOpSSoYfRKM0NHrpjlXCNzoDaiEY6BVwr74_9rVlR615G5qa_7azUeT2oSiTwUtXWdRUkDDJSq9SxkO_cXms4Zes3WZKSAQmv1NOCUxlbU0NX8Vt3y8bo4oE-eaGktKHua8iyvpABrYITyVmfFJRSu0owe5KzB560oFGLIxJ6ipR3jRO59U-R3mmV7-twIc1mVXY6B96rsyRZX6NFrUAEVyVByGbsjV4Jx4qr3ABN0GzUgLi5HxFS7PvMTTTlYBuB0GEBx5uBFWiH5T1nQLfAmpxSMF0050nyDRk1xH8HNARU3MoadsYwCD6N-81IOz9mm6hh8gXo-d8ZUJdGxi1Iegsh4xPV4ntvEf2CGjBFFZk-GYzgX4yfcilR3Q7pS5N56rukSkBFiipuPRjgtCwXoGZeHB1udWTIyNXLOIcwAR6k27KOP1kvBGy0AMcnxTYJK08VaZGQh6BnrXw3hw_n0GXXFVe0gPUO4IxrHFy0vyuWAFg4xOKdJT0bWykigikvFQM8nkOKvJTZjsbulAyeZo-XGr8FNFeO1WtE0QP7zyd5h6wzuLW7pr_-zc8hAvmJyw-Dbs`,
                // Authorization: "Bearer " + Global.get().access_token,
                "ussd": Global.get().token.ussd,
                "token":Global.get().token.token
            },
        };
    }

    getRequestHeaderNoAuth() {
        return {
            headers: {
                "Content-Type": `application/x-www-form-urlencoded`,
                Accept: `application/json`,
                // "Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTc2YjUyN2FiMGNiMGNlOTUwZGQ2OWYxZWJkOWRmMDU0NGVjYTE4MTQ3MDg0OWY0YmRlZmUxMDkzYjRmNGJiNTcyYzQ0ZDE4Zjk5MWU3ZDIiLCJpYXQiOjE2MDk3Mjc1MDAsIm5iZiI6MTYwOTcyNzUwMCwiZXhwIjoxNjQxMjYzNTAwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.vaBxznomz3Z5_HpiRZ5J_Z3PNeKj57APwx_w66IorcmJVI_bVD1gBodFM_nhX6p6LdSJmkX1Eah64WIBJJiZ__emsN54XLt59BHHvOpSSoYfRKM0NHrpjlXCNzoDaiEY6BVwr74_9rVlR615G5qa_7azUeT2oSiTwUtXWdRUkDDJSq9SxkO_cXms4Zes3WZKSAQmv1NOCUxlbU0NX8Vt3y8bo4oE-eaGktKHua8iyvpABrYITyVmfFJRSu0owe5KzB560oFGLIxJ6ipR3jRO59U-R3mmV7-twIc1mVXY6B96rsyRZX6NFrUAEVyVByGbsjV4Jx4qr3ABN0GzUgLi5HxFS7PvMTTTlYBuB0GEBx5uBFWiH5T1nQLfAmpxSMF0050nyDRk1xH8HNARU3MoadsYwCD6N-81IOz9mm6hh8gXo-d8ZUJdGxi1Iegsh4xPV4ntvEf2CGjBFFZk-GYzgX4yfcilR3Q7pS5N56rukSkBFiipuPRjgtCwXoGZeHB1udWTIyNXLOIcwAR6k27KOP1kvBGy0AMcnxTYJK08VaZGQh6BnrXw3hw_n0GXXFVe0gPUO4IxrHFy0vyuWAFg4xOKdJT0bWykigikvFQM8nkOKvJTZjsbulAyeZo-XGr8FNFeO1WtE0QP7zyd5h6wzuLW7pr_-zc8hAvmJyw-Dbs`,
                // "Authorization": 'Bearer ' + Global.get().access_token,
            },
        };
    }

    getApiUrl() {
        return this.isProduction ? this.apiUrlProduction : this.apiUrl;
    }

    getApiUrlNotification(){
        return this.isProduction ? this.apiUrlNotificationProduction : this.apiUrlNotification;
    }

    getApiUrlSubscription(){
        return this.isProduction ? this.apiUrlSubscriptionProduction : this.apiUrlSubscription;
    }

    getApiUrlArticle(){
        return this.isProduction ? this.apiUrlArticleProduction : this.apiUrlArticle;
    }

    login() {
        return this.getApiUrl() + "loginAdmin";
    }

    getProductList(){
        return this.getApiUrl() + "item/list"
    }

    getUserList(){
        return this.getApiUrl() + "user/list"
    }

    getStoreList(){
        return this.getApiUrl() + "store/list"
    }

    getProductCategoryList(){
        return this.getApiUrl() + "items/category"
    }

    uploadImage(){
        return this.getApiUrl() + "uploadImage"
    }

    addProduct(){
        return this.getApiUrl() + "item"
    }

    topStatistics(){
        return this.getApiUrl() + "topStatistics"
    }

    topStores(){
        return this.getApiUrl() + "admin/listStores"
    }
    
    listArtikel(){
        return this.getApiUrl() + "article/list"
    }

    saveArtikel(){
        return this.getApiUrl() + "article"
    }

    gaReport(){
        return this.getApiUrl() + "analytic/getData"
    }

    countUser(){
        return this.getApiUrl() + "admin/countUser"
    }

    adminMarketing(){
        return this.getApiUrl() + "admin/marketing"
    }

    adminListMarketing(){
        return this.getApiUrl() + "admin/listMarketing"
    }

    sendPushNotification(){
        return this.getApiUrlNotification() + "sendPushNotifAllUser"
    }

    sendScheduledNotification(){
        return this.getApiUrlNotification() + "schedulerNotif"
    }

    listScheduledNotification(){
        return this.getApiUrlNotification() + "schedulerNotif/List"
    }

    listBuktiTransferSubscription(){
        return this.getApiUrlSubscription() + "buktiTransfer/list";
    }

    manualSubscription(){
        return this.getApiUrlSubscription() + "buktiTransfer/manualSubscription";
    }
    
    verifySubscription(idSubscription){
        return this.getApiUrlSubscription() + "buktiTransfer/verify/" + idSubscription;
    }

    rejectSubscription(idSubscription){
        return this.getApiUrlSubscription() + "buktiTransfer/reject/" + idSubscription;
    }

    storeTransactionList(){
        return this.getApiUrl() + "transaction/list";
    }

    subscriptionSettingList(){
        return this.getApiUrlSubscription() + "settings/list";
    }

    subscriptionSettingUpdate(settingId){
        return this.getApiUrlSubscription() + "settings/" + settingId;
        // return this.getApiUrlSubscription() + "settings";
    }

    qrisProviderList(){
        return this.getApiUrl() + "qrisProvider/list/";
    }

    qrisProviderAdd(){
        return this.getApiUrl() + "qrisProvider";
    }

    qrisProviderUpdate(qrisProviderId){
        return this.getApiUrl() + "qrisProvider/" + qrisProviderId;
    }

    listVoucher(){
        return this.getApiUrl() + "voucher/list";
    }

    updateVoucher(voucherId){
        return this.getApiUrl() + "voucher/" + voucherId;
    }

    addVoucher(){
        return this.getApiUrl() + "voucher";
    }

    getVoucherDetail(voucherId){
        return this.getApiUrl() + "voucher/" + voucherId;
    }

    surveyList(){
        return this.getApiUrlArticle() + "survey/list";
    }

    surveyListAnswers(){
        return this.getApiUrlArticle() + "survey/answer/list";
    }

    csList(){
        return this.getApiUrl() + "admin/cpSetting/contact_person";
    }

    csUpdate(idCs){
        return this.getApiUrl() + "admin/cpSetting/contact_person/"+idCs;
    }
    
    findUser(noKtp){
        return this.getApiUrl() + "admin/detileMarketing/"+noKtp;
    }

    deleteNotification(notificationId){
        return this.getApiUrlNotification() + "schedulerNotif/"+notificationId;
    }

    uploadProductBulk(noKtp){
        return this.getApiUrl() + "uploadItemXls";
    }

    uploadUserBulk(noKtp){
        return this.getApiUrl() + "uploadUserXls";
    }

    addUser(){
        return this.getApiUrl() + "signUp";
    }


}

const instance = new Api();
Object.freeze(instance);

export default instance;
