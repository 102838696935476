import React, { Component } from 'react';
import "./DashBoardStoreManagement.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import { Button, FormControlLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@mui/material';

class SubscriptionSetup extends Component {
    state = { 
        settingList: [],
        dataChanged: false
    }

    handleSaveSettings = () => {
        const params = [...this.state.settingList];
        params.forEach(item => delete item.changed);
        console.log("PARAMS_UPDATE", params);
        // return;
        axios.put(
            Api.subscriptionSettingUpdate(params[0]._id),
            params,
            Api.getRequestHeader()
          ).then((response) => {
            console.log("UPDATE_RESPONSE", response.data);
            this.loadSubscriptionSettings();
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }

    getFilterClasses = (type) => {
        let classes = "col-6";
        if(type === this.state.type){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }
    
    loadSubscriptionSettings = () => {

        const params = {
            filter: {},
            sort: {created_at: 1},
            limit: 100
        };

        axios.post(
            Api.subscriptionSettingList(),
            params,
            Api.getRequestHeader()
          ).then((response) => {
            let settings = response.data.data;
            settings.forEach(item => delete item.changed);
            console.log("AFTER_DELETE_CHANGED", settings);
            this.setState({settingList: settings, dataChanged: false}, () => {});
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }

    // handleSwitchChange = (event, data, fieldName) => {
    handleSwitchChange = (event, params) => {
        params.newValue = event.target.checked;
        this.handleSettingChange(params);
    }

    handleTextFieldChange = (event, params) => {
        params.newValue = event.target.value;
        this.handleSettingChange(params);
    }

    handleSettingChange = (params) => {
        console.log(params);
        const settings = [...this.state.settingList];
        const filter = settings.filter(item => params.data._id === item._id);

        if(filter && filter.length > 0){
            if(params?.parentField === "features" && params?.index >= 0){
                filter[0][params.parentField][params?.index][params?.fieldName] = params.newValue;
            }else{
                filter[0][params.fieldName] = params.newValue;
            }
            filter[0].changed = true;
        }

        this.setState({settingList: settings, dataChanged: true}, () => {
            // console.log("SETTINGCHANGE_AFTER", this.state.settingList);
        });

    }

    componentDidMount(){
        this.loadSubscriptionSettings();
    }

    isDataChanged = () => {
        return this.state.settingList.filter(item => item.changed === true).length > 0 ? true : false;
    }

    render(){
        return(
            <div className="container-fluid no-padding">
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableCell align='center'><strong>Fitur</strong></TableCell>
                            <TableCell align='center'><strong>Free</strong></TableCell>
                            <TableCell align='center'><strong>Basic</strong></TableCell>
                            <TableCell align='center'><strong>Pro</strong></TableCell>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.settingList.map(item => {
                                    // return 
                                    // item.limit_name === "Laporan Keuangan" ? (
                                    //     <TableRow key={item._id}>
                                    //         <TableCell width={"20%"}><strong>{item.limit_name}</strong></TableCell>
                                    //         {
                                    //             item?.free ? (
                                    //                 <TableCell>
                                    //                     <FormControlLabel control={<Switch checked={item?.free?.per_karyawan} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "per_karyawan", parentField: "free"})} />} label={"Per Karyawan"} />
                                    //                     <br />
                                    //                     <FormControlLabel control={<Switch checked={item?.free?.per_profile} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "per_profile", parentField: "free"})} />} label={"Per Profile"} />
                                    //                 </TableCell>
                                    //             ) : (<TableCell></TableCell>)
                                    //         }
                                    //         {
                                    //             item?.basic ? (
                                    //                 <TableCell>
                                    //                     <FormControlLabel control={<Switch checked={item?.basic?.per_karyawan} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "per_karyawan", parentField: "basic"})} />} label={"Per Karyawan"} />
                                    //                     <br />
                                    //                     <FormControlLabel control={<Switch checked={item?.basic?.per_profile} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "per_profile", parentField: "basic"})} />} label={"Per Profile"} />
                                    //                 </TableCell>
                                    //             ) : (<TableCell></TableCell>)
                                    //         }
                                    //         {
                                    //             item?.pro ? (
                                    //                 <TableCell>
                                    //                     <FormControlLabel control={<Switch checked={item?.pro?.per_karyawan} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "per_karyawan", parentField: "pro"})} />} label={"Per Karyawan"} />
                                    //                     <br />
                                    //                     <FormControlLabel control={<Switch checked={item?.pro?.per_profile} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "per_profile", parentField: "pro"})} />} label={"Per Profile"} />
                                    //                 </TableCell>
                                    //             ) : (<TableCell></TableCell>)
                                    //         }
                                    //     </TableRow>
                                    // ) : (
                                    return (
                                        <>
                                        <TableRow key={item._id}>
                                            <TableCell width={"20%"}><strong>{item.limit_name}</strong></TableCell>
                                            <TableCell>
                                                <FormControlLabel control={<Switch checked={item.is_active} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "is_active"})} />} label={item.is_active ? "Menu Aktif" : "Menu Tidak Aktif"} />
                                                <br />
                                                {
                                                    item.free_daily_limit !== undefined ? (<TextField 
                                                        placeholder='Belum diatur' 
                                                        label={`Jumlah ${item.limit_name}`} 
                                                        variant="outlined" 
                                                        size='small' 
                                                        type='number' 
                                                        fullWidth
                                                        value={item.free_daily_limit}
                                                        onChange={(event) => this.handleTextFieldChange(event, {data: item, fieldName: "free_daily_limit"})}
                                                    />) : (<></>)
                                                } 
                                                <br />
                                            {
                                                item.features && item.features.map((fitur, index) => {
                                                    return (
                                                            <FormControlLabel control={<Switch checked={fitur?.is_active} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "is_active", parentField: "features", index: index})} />} label={fitur.limit_name} />
                                                    )
                                                })
                                            }
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel control={<Switch checked={item.is_active_basic} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "is_active_basic"})} />} label={item.is_active_basic ? "Menu Aktif" : "Menu Tidak Aktif"} />
                                                <br />
                                                {
                                                    item.basic_daily_limit !== undefined ? (<TextField 
                                                        placeholder='Belum diatur' 
                                                        label={`Jumlah ${item.limit_name}`} 
                                                        variant="outlined" 
                                                        size='small' 
                                                        type='number' 
                                                        fullWidth
                                                        value={item.basic_daily_limit}
                                                        onChange={(event) => this.handleTextFieldChange(event, {data: item, fieldName: "basic_daily_limit"})}
                                                    />) : (<></>)
                                                } 
                                                <br />
                                                {
                                                item.features && item.features.map((fitur, index) => {
                                                    return (
                                                            <FormControlLabel control={<Switch checked={fitur?.is_active_basic} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "is_active_basic", parentField: "features", index: index})} />} label={fitur.limit_name} />
                                                    )
                                                })
                                            }
                                                
                                            </TableCell>
                                            <TableCell>
                                                <FormControlLabel control={<Switch checked={item.is_active_pro} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "is_active_pro"})} />} label={item.is_active_pro ? "Menu Aktif" : "Menu Tidak Aktif"} />
                                                <br />
                                                {
                                                    item.pro_daily_limit !== undefined ? ( <TextField 
                                                        placeholder='Belum diatur'
                                                        label={`Jumlah ${item.limit_name}`} 
                                                        variant="outlined" 
                                                        size='small' 
                                                        type='number' 
                                                        fullWidth
                                                        value={item.pro_daily_limit}
                                                        onChange={(event) => this.handleTextFieldChange(event, {data: item, fieldName: "pro_daily_limit"})}
                                                    />) : (<></>)
                                                } 
                                                <br />
                                                {
                                                item.features && item.features.map((fitur, index) => {
                                                    return (
                                                            <FormControlLabel control={<Switch checked={fitur?.is_active_pro} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "is_active_pro", parentField: "features", index: index})} />} label={fitur.limit_name} />
                                                    )
                                                })
                                            }
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow key={item._id}>
                                            <TableCell>
                                            {
                                                item.features && item.features.map(fitur => {
                                                    return (
                                                            <FormControlLabel control={<Switch checked={fitur?.is_active} size='small' onChange={(event) => this.handleSwitchChange(event, {data: item, fieldName: "per_karyawan", parentField: "free"})} />} label={fitur.limit_name} />
                                                    )
                                                })
                                            }
                                            </TableCell>
                                        </TableRow> */}
                                        </>
                                    )
                                    // );
                                })
                            }
                        </TableBody>
                        <TableFooter style={{padding: "5px", textAlign: "right"}}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <Button disabled={!this.state.dataChanged} onClick={this.handleSaveSettings} fullWidth variant="contained">Save Changes</Button>
                            </TableCell>
                        </TableFooter>
                    </Table>
                </TableContainer>
                
            </div>
        );
    }
}
 
export default SubscriptionSetup;