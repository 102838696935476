import { Delete, DoneAll, Error, Image, ImageOutlined } from '@mui/icons-material';
import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import Api from "../../../lib/Api";
import Global from "../../../lib/Global";
import moment from 'moment/moment';
import DownloadTemplateProductBulk from '../DownloadTemplateProduct';
import { FileUploader } from 'react-drag-drop-files';
import { Alert, Button, ButtonBase, CircularProgress, LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';

function DialogAturSubscription(props) {

    const [file, setFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState("BASIC");
    const [jumlahHari, setJumlahHari] = useState(1);
    const [storeInfo, setStoreInfo] = useState(null);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [subscriptionHistory, setSubscriptionHistory] = useState([]);
    const [subscriptionHistoryErrorMessage, setSubscriptionHistoryErrorMessage] = useState(null);

    const handlesubscriptionTypeChange = (event, value) => {
        setSubscriptionType(value);
    }

    const handleJumlahHariChange = (event) => {
        let value = parseInt(event.target.value ?? 0);
        setJumlahHari(value);
    }

    const submit = () => {

        // if(!(jumlahHari !== undefined && jumlahHari !== null))

        let params = {
            store_id: props.storeInfo?.id,
            subscription_type: subscriptionType,
            jumlah_hari: jumlahHari
        };
        setIsSubmitting(true);
        axios
            .post(
                Api.manualSubscription(),
                params,
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("STORERESPONSE", response.data);
                const responseData = response.data;
                const subsDays = jumlahHari;
                const subsType = subscriptionType;
                if(responseData.success){
                    setStoreInfo(responseData.data);
                    props.showSnackbar("Masa aktif subscription "+subsType+" berhasil ditambahkan " + subsDays + " hari");
                    loadSubscriptionHistory();
                }

                setIsSubmitting(false);
            
            })
            .catch((error) => {
                setIsSubmitting(false);
                props.showSnackbar(error.message);
                console.error("There was an error!", error);
            });
    }

    const loadSubscriptionHistory = () => {

        let params = {
            filter: {
                store_id: props?.storeInfo?.id
            },
            sort  : {created_at: -1},
            limit : 100
        };
    
        console.log("PARAMS USERS", JSON.stringify(params), "HEADER", Api.getRequestHeader());
        
        setIsLoadingHistory(true);

        axios
            .post(
                Api.listBuktiTransferSubscription(),
                params,
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("HISTORY", response.data);
                const responseData = response.data;
                setSubscriptionHistory(responseData.data);
                setIsLoadingHistory(false);
            })
            .catch((error) => {
                let errorMessage = "History transaksi error! code : " + error.response.status + ", message : " + error.response.data.msg;
                // setErrorMessage(errorMessage);
                setIsLoadingHistory(false);
                setSubscriptionHistoryErrorMessage(errorMessage);
                // props.showSnackbar(errorMessage);
            });
        
    };

    const rejectSubscription = (subscriptionData) => {

        axios.post(
            Api.rejectSubscription(subscriptionData._id),{reject_reason: "rejected by system"},Api.getRequestHeader()
          ).then((response) => {
            console.log("REJECT_SUBSCRIPTION", response.data);
            props.showSnackbar("Subscription " + subscriptionData.subscription_type + " " + subscriptionData.subscription_period + " hari berhasil dibatalkan")
            loadSubscriptionHistory();
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
        });
    }

    const handleConfirmDelete = (itemHistory) => {
        let histories = [...subscriptionHistory];

        let findHistory = histories.find(item => item._id === itemHistory._id);

        if(findHistory){
            findHistory.askConfirmation = true;
        }

        setSubscriptionHistory(histories);

    }

    useEffect(() => {
        setStoreInfo({...props?.storeInfo});
        loadSubscriptionHistory();
    }, []);

    return <>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Nama Toko</div>
                    <div className="col-8">: {storeInfo?.nama_toko}
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Store ID</div>
                    <div className="col-8">: <strong>{storeInfo?.id}</strong></div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Pemilik</div>
                    <div className="col-8">: {storeInfo?.owner_name}</div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Join Date</div>
                    <div className="col-8">: {moment(storeInfo?.created_at).tz("asia/jakarta").format("DD-MM-Y HH:mm:ss")}</div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">No. Handphone</div>
                    <div className="col-8">: {storeInfo?.owner_ussd}</div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">last Activity</div>
                    <div className="col-8">: {moment(storeInfo?.last_transaction).tz("asia/jakarta").format("DD-MM-Y HH:mm:ss")}</div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Domisili</div>
                    <div className="col-8">: {props.storeInfo?.alamat}</div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Jenis Subscription</div>
                    <div className="col-8">: {props.storeInfo?.subscribe_type}</div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Referal</div>
                    <div className="col-8">: -</div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Akhir Subscription</div>
                    <div className="col-8">: {moment(storeInfo?.valid_thru).tz("asia/jakarta").format("DD-MM-Y HH:mm:ss")}</div>
                </div>
            </div>
        </div>
        <br /><br />
        <h3 className='text-primary'>Tambah Subscription</h3>
        <hr />
        <Paper elevation={0}>
            <div className="row">
                <div className="col-6">
                    <TextField
                        id="txtJumlahHari"
                        label="Jumlah Hari"
                        defaultValue={jumlahHari}
                        type='number'
                        fullWidth
                        size='small'
                        onChange={handleJumlahHariChange}
                    />
                </div>
                <div className="col-6">
                    <ToggleButtonGroup
                        color="primary"
                        value={subscriptionType}
                        exclusive
                        onChange={handlesubscriptionTypeChange}
                        fullWidth
                        size='small'
                    >
                        <ToggleButton value="BASIC">Basic</ToggleButton>
                        <ToggleButton value="PRO">Pro</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <br />
            <Button variant='contained' color='primary' fullWidth onClick={submit} disabled={isSubmitting}>
                Simpan&nbsp; {isSubmitting ? <CircularProgress color='warning' size={15} /> : <></>}
            </Button>
        </Paper>
        
        <br /><br />
        <h3 className='text-primary'>Riwayat Subscription</h3>
        
        {
            isLoadingHistory && <LinearProgress />
        }
        
        {
            !isLoadingHistory && <hr />
        }
        
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>Tgl Transaksi</TableCell>
                    <TableCell>Jumlah Hari</TableCell>
                    <TableCell>Jenis Subscription</TableCell>
                    <TableCell>Berlaku Hingga</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    subscriptionHistory.map(item => {
                        return (
                            <TableRow>
                                <TableCell>{moment(item.created_at).tz("asia/jakarta").format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                                <TableCell>{item.subscription_period}</TableCell>
                                <TableCell>{item.subscription_type}</TableCell>
                                <TableCell>{moment(item.valid_thru).tz("asia/jakarta").format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                                {/* <TableCell>
                                    {
                                        item.askConfirmation === true && <Button variant='outlined' size='small' color='error' onClick={() => rejectSubscription(item)}>Reject Now</Button>
                                    }

                                    {
                                        item.askConfirmation !== true && <Button variant='text' size='small' onClick={() => handleConfirmDelete(item)}><Delete color='error'/></Button>
                                    }
                                    
                                </TableCell> */}
                            </TableRow>
                        );
                    })
                }
                {
                    subscriptionHistoryErrorMessage !== null && (
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Alert severity="error">{subscriptionHistoryErrorMessage}</Alert>
                            </TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>

        
    </>;
}

export default DialogAturSubscription;