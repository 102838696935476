import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { ImportExport } from "@mui/icons-material";
import moment from "moment-timezone";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportSurvey extends Component {

    getSurvey = () => {
        let leadsData = [];
        this.props.dataAnswers.forEach((element) => {
            element.answer.forEach((ans, index) => {
                console.log(this.props.dataSurvey.questions[index].questionText);
                element.question = this.props.dataSurvey.questions[index].questionText;
            });
            
            leadsData.push(element);
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<button className="btn btn-primary btn-block btn-sm"><ImportExport />Export ke Excel</button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getSurvey} name="Hasil Survey">
                    <ExcelColumn label="Waktu" value={(col) => moment(col.created_at).tz("asia/jakarta").format("DD-MM-YYYY HH:mm:ss")}/>
                    <ExcelColumn label="Nama" value="user_name"/>
                    <ExcelColumn label={"Question"} value="question"/>

                    {/* <ExcelColumn label="Tempat Lahir" value="tempat_lahir"/>
                    <ExcelColumn label="Tanggal Lahir" value="tanggal_lahir"/>
                    <ExcelColumn label="Kode Referal" value="referal_code"/>
                    <ExcelColumn label="Join Date" value={(col) => moment(col.created_at).tz("asia/jakarta").format("DD-MM-Y h:mm:ss a")}/>
                    <ExcelColumn label="Downline" value={(col) => col.downline.length}/> */}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportSurvey;