import React, { Component } from 'react';
import "./DashBoardProuctsManagemet.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import querystring from "querystring";
import App from '../../App';
import moment from "moment";
import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/icons-material/ListItemIcon';
// import ListItemText from '@mui/icons-material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import CloseRounded from '@mui/icons-material/CloseRounded';
import { Box, Link, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
// import { DropzoneArea } from 'material-ui-dropzone';
import TabContentInputArtikelTutorial from "./TabContentInputArtikelTutorial";
import TabContentUploadCsv from "./TabContentUploadCsv";
import DataTable from 'react-data-table-component';

class DashboardArtikelTutorial extends Component {
    state = { 
        columns : [
            {
                // label: "Judul",
                // key: "title",
                // name: "title",
                // sort: false
                name: "Judul",
                selector: row => row.title,
                sortable: true
            }, 
            {
                // label: "Status",
                // key: "status",
                // name: "status",
                // sort: true
                name: "Status",
                selector: row => row.status,
                sortable: true
            }, 
            {
                // label: "Kategori",
                // key: "category",
                // name: "category",
                // sort: true
                name: "Kategori",
                selector: row => row.category,
                sortable: true
            },
            {
                // label: "Action",
                // key: "action"
                name: "Action",
                selector: row => row.action
            }
        ],
        data : [], 
        originalData: [],
        right: false,
        selectedProduct : null,
        value : 0,
        files: null,
    }

    // useStyles = makeStyles({
    //     root: {
    //       flexGrow: 1,
    //     },
    //   });

    options = {
        filterType: 'checkbox',
        customAction: this.action
    };

    loadProducts = () => {
        const params = {
            filter : {},
            sort : {_id:-1},
            limit : 10
        };
    
        console.log("PARAMS PRODUCT", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .post(
            Api.listArtikel(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("PRODUCTRESPONSE", response.data);
            const responseData = response.data.data;
            
            let displayProducts = [];
            let originalData = [];

            responseData.forEach(product => {
                originalData.push(product);
                displayProducts.push({
                  title: product.title, 
                  status: product.status, 
                  category: product.category, 
                  action: this.action(product)
                }
              );
                // displayProducts.push(product);
            });

            // console.log("DISPLAY : ", displayProducts);
            
            this.setState({data: displayProducts, originalData: originalData});
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    componentDidMount() {
        this.loadProducts();
        this.setState({value: 0});
    }
    
    toggleDrawer = (open, productInfo) => (event) => {
        console.log(event);
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      this.setState({right: open, selectedProduct: productInfo });
    };

    action = (productInfo) => {
      return (<span className="btn btn-primary" onClick={this.toggleDrawer(true, productInfo)}>Edit</span>);
    }

    renderImage = (imageUrl) => {
        console.log("IMAGEURL : ", imageUrl);
        return (<img className="img-responsive" src={imageUrl} style={{width:50}} alt=""/>);
    }

    handleTabChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };

    handleSaveFile(files){
        console.log("FILES : ", files);
        this.setState({
            files: files,
        });
    }

    handleCloseForm = () => {
        this.toggleDrawer(false, {});
    }

    handleDataChange = () => {
        this.loadProducts();
    }

    render() { 
        return ( 
            <>
            <div className="container-fluid no-padding">

            {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{})}
                    >
                    <div className="container input-product-container" style={{width: 1000}}>
                        <h1 className="mt-4">Input Artikel / Tutorial</h1>
                        <>
                            {/* <Paper className={this.useStyles.root}>
                                
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab key="tab0" label="" />
                                </Tabs>
                            </Paper>
                            <TabPanel value={this.state.value} index={0}> */}
                                <TabContentInputArtikelTutorial selectedProduct={this.state.selectedProduct} onDataChange={this.handleDataChange} closeForm={this.handleCloseForm}/>
                            {/* </TabPanel> */}
                        </>
                    </div>
                  </Drawer>
                </React.Fragment> 
              ))}

                <div className="container-fluid header-page">
                    <div className="row">
                        <div className="col-5">
                            <h1 className="mt-4">Artikel & Tutorial</h1>
                        </div>
                        <div className="col-7 text-right">
                            <br />
                            <span className="mt-4 blue-button" onClick={this.toggleDrawer(true, {})}>Tambah +</span>
                        </div>
                    </div>
                </div>

                <div className="container-fluid dashboard-content">
                    <DataTable
                        title={"Daftar Artikel & Tutorial"}
                        data={this.state.data} 
                        columns={this.state.columns} 
                        options={this.options} 
                    />
                </div>
                
            </div>
            </>
         );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
 
export default DashboardArtikelTutorial;