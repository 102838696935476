import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
// import { DropzoneArea } from 'material-ui-dropzone';
import React, { Component } from 'react';
import axios from "axios";
import Api from "../../lib/Api";

class TabComponentInputProductManual extends Component {
    state = { 
        id: null,
        namaProduk: "",
        qty: 0,
        hargaJual: 0,
        hargaPromo: 0,
        hargaModal: 0,
        sku: "",
        kategori: "",
        status: "active",
        files: [],
        categories:[],
        selectedCategory : "",
     }

     handleSaveFile = (files) => {
        console.log("FILES : ", files);
        this.setState({
            files: files,
        });
    }

    handleSaveNamaProduk = (event) =>{
        this.setState({namaProduk: event.target.value});
    }

    handleSaveQty = (event) =>{
        this.setState({namaProduk: event.target.value});
    }

    handleSaveHargaJual = (event) =>{
        this.setState({namaProduk: event.target.value});
    }

    handleSavehargaPromo = (event) =>{
        this.setState({namaProduk: event.target.value});
    }

    handleSaveHargaModal = (event) =>{
        this.setState({namaProduk: event.target.value});
    }

    handleSaveSku = (event) =>{
        this.setState({namaProduk: event.target.value});
    }

    handleSaveEtalase = (event) =>{
        this.setState({namaProduk: event.target.value});
    }

    handleCheckBoxChange = (event) => {

        // if(event.target.checked){
        //     this.props.onChecked(this.state.status);
        // }else{
        //     this.props.onUnchecked(this.state.selectedSales);
        // }

        this.setState({status: event.target.checked ? "Active" : "Tidak Active"});
      }

    loadProductCategories = () => {
        const params = {
            filter:{store_id: "possax" },
            sort:{},
            limit:100
        };
    
        console.log("PARAMS CATEGORIES", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
          .get(
            Api.getProductCategoryList(),
            // params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("CATEGORIESRESPONSE", response.data);
            const responseData = response.data.category;
        
            this.setState({categories: responseData});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

      initProduct = (product) => {
        this.setState(
            {
                id: product.id,
              namaProduk: product.nama,
              qty: product.quantity,
              hargaJual: product.harga_jual,
              hargaPromo: product.harga_promo,
              hargaModal: product.harga_modal,
              sku: product.sku,
              kategori: product.kategori,
              status: product.status,
              foto: product.foto,
              files: [],
              selectedCategory : product.kategori
            }
        );
      }

      componentDidMount() {
          this.loadProductCategories();
          if(this.props.selectedProduct !== null){
            this.initProduct(this.props.selectedProduct);
            console.log("initproduct", this.props.selectedProduct);
          }
      }

      handleSelectCategory = (value) => {
          console.log("SELECTED CATEGORY : ",value);
          this.setState({selectedCategory: value, kategori: value});
      }
      
      uploadFoto = () => {
        const data = new FormData() 
        data.append('photo', this.state.files[0]);
        axios
        .post(Api.uploadImage(), data, Api.getRequestHeader())
        .then((response) => {
            console.log("RESPONSEUPLOAD : ", response.data);
        //   this.setState({files: []});
            const imagePath = response.data.path;
            if(this.state.id === null){
                this.addProduct(imagePath);
            }else{
                this.updateProduct(imagePath);
            }
            
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message });
          console.error("There was an error!", error);
        });
      }

      addProduct = (photoUrl) => {
          const params = [{
            nama : this.state.namaProduk,
            foto : photoUrl,
            quantity : 0,
            harga_promo : 0,
            harga_jual : 0,
            harga_modal : 0,
            sku : null,
            kategori : this.state.selectedCategory,
            store_id : "possax",
            status : this.state.status
          },];

          console.log("PARMADD", params);

        axios
        .post(
          Api.addProduct(),
          params,
          Api.getRequestHeader()
        )
        .then((response) => {
          console.log("ADDPRODUCTREPONSE", response.data);
          this.setState(
              {
                namaProduk: "",
                qty: 0,
                hargaJual: 0,
                hargaPromo: 0,
                hargaModal: 0,
                sku: "",
                kategori: "",
                status: "active",
                foto: null,
                files: [],
                selectedCategory : ""
              }
          );
          this.props.onDataChange();
          this.props.closeForm();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      }

      updateProduct = (photoUrl) => {
        const params = {
            id: this.state.id,
          nama : this.state.namaProduk,
          foto : photoUrl,
          quantity : 0,
          harga_promo : 0,
          harga_jual : 0,
          harga_modal : 0,
          sku : null,
          kategori : this.state.selectedCategory,
          store_id : "possax",
          status : this.state.status
        };

        console.log("PARMUPDATE", params);

      axios
      .put(
        Api.addProduct() + "/" + this.state.id,
        params,
        Api.getRequestHeader()
      )
      .then((response) => {
        console.log("UPDATEPRODUCTREPONSE", response.data);
        this.setState(
            {
                id: null,
              namaProduk: "",
              qty: 0,
              hargaJual: 0,
              hargaPromo: 0,
              hargaModal: 0,
              sku: "",
              kategori: "",
              status: "active",
              foto: null,
              files: [],
              selectedCategory : ""
            }
        );
        this.props.onDataChange();
        this.props.closeForm();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    }

    saveProduct = () => {
        if(this.state.id === null){//assumed this is new product
            if(this.state.files.length > 0){
                this.uploadFoto();
            }else{
                this.addProduct(null);
            }
        }else{//assumed we will update the product info
            if(this.state.files.length > 0){
                this.uploadFoto();
            }else{
                this.updateProduct(this.state.foto);
            }
        }
    }

    render() { 
        return ( 
            <>
            <TextField
                className="input-textfield"
                id="outlined-required"
                label="Nama Produk"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveNamaProduk}
                onKeyDown={this.onKeyDownHandler}
                value={this.state.namaProduk}
                // style={this.CssTextField}
            />
            {/* <br /><br />
            <TextField
                className="input-textfield"
                id="outlined-required"
                label="Qty"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveQty}
                onKeyDown={this.onKeyDownHandler}
                // style={this.CssTextField}
            /> */}
            {/* <br /><br />
            <TextField
                className="input-textfield"
                id="outlined-required"
                label="Harga Jual"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveHargaJual}
                onKeyDown={this.onKeyDownHandler}
                // style={this.CssTextField}
            />
            <br /><br />
            <TextField
                className="input-textfield"
                id="outlined-required"
                label="Harga Promo"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSavehargaPromo}
                onKeyDown={this.onKeyDownHandler}
                // style={this.CssTextField}
            />
            <br /><br />
            <TextField
                className="input-textfield"
                id="outlined-required"
                label="Harga Modal"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveHargaModal}
                onKeyDown={this.onKeyDownHandler}
                // style={this.CssTextField}
            /> */}
            {/* <br /><br />
            <TextField
                className="input-textfield"
                id="outlined-required"
                label="SKU"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveSku}
                onKeyDown={this.onKeyDownHandler}
                // style={this.CssTextField}
            /> */}
            {/* <br /><br /> */}
            {/* <TextField
                className="input-textfield"
                id="outlined-required"
                label="Etalase"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveEtalase}
                onKeyDown={this.onKeyDownHandler}
                // style={this.CssTextField}
            /> */}
            <br /><br />
            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                // disableClearable
                freeSolo 
                options={this.state.categories.map((option) => {
                    // console.log("LABEL : ", option);
                    return String(option);
                })}
                onChange={(event, newValue) => {
                    // console.log("NEWVALUE : ", event);
                    this.handleSelectCategory(newValue);
                    }} 
                value={this.state.selectedCategory}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Etalase"
                    margin="0"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                />
                )}
            />  

            <br />
            <input type="checkbox" checked={this.state.status === "active"} onChange={this.handleCheckBoxChange} /> Produk Aktif
            {/* <TextField
                className="input-textfield"
                id="outlined-required"
                label="Status"
                defaultValue=""
                variant="outlined"
                onChange={this.handleSaveStatus}
                onKeyDown={this.onKeyDownHandler}
                // style={this.CssTextField}
            /> */}
            <br /><br />
            <div className="">
                <strong>Foto Produk</strong>
                <br/>
                <img style={{width: 150}} className="img-responsive" src={this.state.foto != null && this.state.foto.indexOf("http://") !== -1 ? this.state.foto : Api.getApiUrl() + this.state.foto} alt="" />
            {/* <DropzoneArea
                onChange={this.handleSaveFile.bind(this)}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'image/svg']}
                // showPreviews={true}
                maxFileSize={5000000}
                filesLimit={1}
            /> */}
            </div>
            <br />
            <div className="text-right">
                <div onClick={this.saveProduct} className="btn btn-primary">Simpan</div>
            </div>
            </>
         );
    }
}
 
export default TabComponentInputProductManual;